type Header = [string, number];

export const headers: Header[] = [
    ['Thumbnail', 8],
    ['Details', 16],
    ['Description', 28],
    ['Categories', 8],
    ['List Priority', 8],
    ['Printings', 20],
    ['Actions', 12],
];

export const bull = <span>&#8226;</span>;