import { useState } from "react";
import Artwork from "../../../data/Artwork";
import ConfirmSoldPopover from "./ConfirmSoldPopover";
import ConfirmDeletePopover from "./ConfirmDeletePopover";
import EditingPopover from "./edit/EditingPopover";

enum PopoverState {
    NOMINAL,
    CONFIRM_MARK_SOLD,
    CONFIRM_DELETE,
    EDITING,
}

export interface PopoverControl {
    promptConfirmMarkSold: (artwork: Artwork) => void;
    promptConfirmDelete: (artwork: Artwork) => void;
    beginEditing: (artwork: Artwork | null) => void;
    close: () => void;
}

export function usePopover(): [PopoverControl, () => JSX.Element | null] {
    const [state, setState] = useState<PopoverState>(PopoverState.NOMINAL);
    const [subjectArtwork, setSubjectArtwork] = useState<Artwork | null>(null);

    const control: PopoverControl = {
        promptConfirmMarkSold: (artwork: Artwork) => {
            setState(PopoverState.CONFIRM_MARK_SOLD);
            setSubjectArtwork(artwork);
        },
        promptConfirmDelete: (artwork: Artwork) => {
            setState(PopoverState.CONFIRM_DELETE);
            setSubjectArtwork(artwork);
        },
        beginEditing: (artwork: Artwork | null) => {
            setState(PopoverState.EDITING);
            setSubjectArtwork(artwork);
        },
        close: () => {
            setState(PopoverState.NOMINAL);
            setSubjectArtwork(null);
        },
    };

    const getActivePopover = (): JSX.Element | null => {
        switch (state) {
            case PopoverState.NOMINAL:
                return null;
            case PopoverState.CONFIRM_MARK_SOLD:
                return <ConfirmSoldPopover
                    artwork={subjectArtwork!}
                    control={control}
                />;
            case PopoverState.CONFIRM_DELETE:
                return <ConfirmDeletePopover
                    artwork={subjectArtwork!}
                    control={control}
                />;
            case PopoverState.EDITING:
                return <EditingPopover
                    existing={subjectArtwork ?? null}
                    control={control}
                />;
        }
    }

    return [control, getActivePopover];
}