import React from "react";
import Artwork from "../../../../data/Artwork";
import { PopoverControl } from "../Popover";
import CategoriesArea from "./CategoriesArea";
import { Editor, useEditor } from "./editor";
import ImageArea from "./ImageArea";
import PrintingsArea from "./PrintingsArea";
import { useCloudflare } from "../../../../api/Cloudflare";

enum EditState {
    EDITING,
    SUBMITTING,
    SUCCESS,
}

interface Props {
    existing: Artwork | null;
    control: PopoverControl;
}

/* Recall that the uniqueness of an Artwork is defined by its cfID which is
 * exactly the ID of its source image within Cloudflare's image delivery
 * service. Therefore, creation of or edition of any source image necessitates
 * creation of a new Artwork object.
 * 
 * Therefore, our stategy is as follows:
 *   1. If creating a new Artwork, require upload of an image to Cloudflare
 *      image delivery before allowing ProtoArtwork submission.
 *   2. If editing an existing Artwork, optionally allow user to upload a new
 *      image to Cloudflare image delivery. However, only delete previous
 *      Artwork data and source image upon successful process completion.
 *   3. In both cases, allow user to replace source image within submission form
 *      multiple times, and only perform upload upon complete submission. */

export default function EditingPopover(props: Props): JSX.Element {
    const editor: Editor = useEditor(props.existing);
    const [state, setState] = React.useState(EditState.EDITING);
    const [errors, setErrors] = React.useState<string[]>([]);

    const form = <>
        <div className="artwork-edit-form-container">
            <ImageArea editor={editor} />
            <div className="artwork-edit-form">
                <div className="flex-column" style={{ gap: '0.3rem' }}>
                    <label>Name</label>
                    <input
                        type="text"
                        value={editor.name.value}
                        onChange={e => editor.name.update(e.target.value)}
                    />
                </div>
                <div className="flex-column" style={{ gap: '0.3rem' }}>
                    <label>Description</label>
                    <textarea
                        value={editor.description.value}
                        onChange={e => editor.description.update(e.target.value)}
                    />
                </div>
                <div className="flex-column" style={{ gap: '0.3rem' }}>
                    <label>Medium</label>
                    <input
                        type="text"
                        value={editor.medium.value}
                        onChange={e => editor.medium.update(e.target.value)}
                    />
                </div>
                <div style={{
                    display: "flex",
                    gap: "1.5rem",
                    width: "100%",
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "40%",
                        gap: "1rem",
                    }}>
                        <div className="flex-column" style={{ gap: '0.3rem' }}>
                            <label>List Priority</label>
                            <input
                                type="number"
                                value={editor.priority.value}
                                onChange={e => editor.priority.update(e.target.value)}
                                style={{ width: "40%" }}
                            />
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.3rem",
                        }}>
                            <label>Categories</label>
                            <CategoriesArea editor={editor} />
                        </div>
                    </div>
                    <div style={{
                        width: "60%",
                    }}>
                        <PrintingsArea editor={editor} />
                    </div>
                </div>
            </div>
        </div>
        <div style={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                width: "65%",
                color: "var(--teacup-red)",
                gap: "0.3rem",
            }}>
                {errors.map((error, index) => <div key={index}>
                    {`> ${error}`}
                </div>)}
            </div>
        </div>
        <div style={{
            display: "flex",
            justifyContent: "center",
            gap: "3rem",
            width: "100%",
        }}>
            <button
                className="artwork-edit-cancel-button"
                onClick={() => props.control.close()}
            >
                Cancel
            </button>
            <button
                className="artwork-edit-submit-button"
                onClick={() => {
                    setErrors([]);
                    setState(EditState.SUBMITTING);
                    editor.submit({
                        onError: errors => {
                            setErrors(errors);
                            setState(EditState.EDITING);
                        },
                        onSuccess: () => {
                            setState(EditState.SUCCESS);
                            // reload to allow new images to propagate
                            window.location.reload();
                        },
                    });
                }}
            >
                Submit
            </button>
        </div>
    </>;

    const spinner = <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        alignItems: "center",
    }}>
        <div>
            Uploading . . .
        </div>
        <div style={{
            color: "var(--teacup-red)"
        }}>
            Please do not navigate away from this page.
        </div>
    </div>;

    const success = <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        alignItems: "center",
    }}>
        Upload Successful!
        <button
            className="artwork-edit-submit-button"
            onClick={() => props.control.close()}
        >
            Close
        </button>
    </div>;

    const getContent = () => {
        switch (state) {
            case EditState.EDITING:
                return form;
            case EditState.SUBMITTING:
                return spinner;
            case EditState.SUCCESS:
                return success;
        }
    };

    return <div className="artwork-edit-container">
        {getContent()}
    </div>;
}