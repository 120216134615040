import React from "react";
import styled from "styled-components";
import { color } from "../../constant";
import { fontSize } from "../../constant";
import SVG, { Icon } from "../common/SVG";
import CartLink from "../cart/CartLink";

const MOTTO = `Transforming my passion for painting into captivating creations you'll treasure`;
const INSTAGRAM_URL = `https://www.instagram.com/terischottlerartist/`;
const TIKTOK_URL = `https://www.tiktok.com/@teriartvibe?t=8iSuqzfCQg6`;

export default function GalleryBanner(): JSX.Element {
  const [hovered, setHovered] = React.useState<Icon | null>(null);

  return <Banner>
    <div className="flex-column" style={{
      justifyContent: "center",
      gap: "clamp(0px, 2vw, 10px)",
      maxWidth: "clamp(0px, 70%, 450px)",
    }}>
      <p style={{
        fontSize: fontSize.header,
      }}>
        Teri Schottler
      </p>
      <p style={{
        fontSize: fontSize.smol,
      }}>
        <i>{MOTTO}</i>
      </p>
    </div>
    <div style={{
      display: "flex",
      gap: "clamp(0px, 4vw, 26px)",
      height: "100%",
      paddingTop: "clamp(0px, 4vw, 22px)",
      boxSizing: "border-box",
    }}>
      <a
        href={INSTAGRAM_URL}
        target="_blank"
        rel="noreferrer"
        onMouseEnter={() => setHovered(Icon.INSTAGRAM)}
        onMouseLeave={() => setHovered(null)}
        style={{
          height: "fit-content",
        }}
      >
        <SVG
          icon={Icon.INSTAGRAM}
          widthVW={7.0}
          maxWidth={35}
          color={hovered === Icon.INSTAGRAM ? color.RED : color.WHITE}
        />
      </a>
      <a
        href={TIKTOK_URL}
        target="_blank"
        rel="noreferrer"
        style={{
          // nudge inline with other icons
          marginTop: "clamp(0px, 0.35vw, 4px)",
          height: "fit-content",
        }}
        onMouseEnter={() => setHovered(Icon.TIKTOK)}
        onMouseLeave={() => setHovered(null)}
      >
        <SVG
          icon={Icon.TIKTOK}
          widthVW={6.0}
          maxWidth={30}
          color={hovered === Icon.TIKTOK ? color.RED : color.WHITE}
        />
      </a>
    </div>
  </Banner>;
}

const Banner = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;

  width: 100%;
  height: clamp(0px, 24vw, 128px);
  padding-left: 4vw;
  padding-right: 4vw;

  background: linear-gradient(175deg, var(--teacup-light-blue) 30%, var(--teacup-pink) 100%);
`;