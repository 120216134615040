import styled from "styled-components";
import SVG, { ASPECT_RATIOS, Icon } from "../common/SVG";
import React from "react";

interface Props {
    count: number;
    textColor: string;
    hoverTextColor?: string;

    // props which will be passed to wrapped SVG
    widthVW: number;
    maxWidth?: number;
    color: string;
    hoverColor?: string;
}

// scale of text relative to icon height
const fontScale: number = 0.55;

export default function CartIcon(props: Props): JSX.Element {
    // manually track hover for propagation to SVG
    const [isHovered, setIsHovered] = React.useState<boolean>(false);

    // calculate width in pixels for convenience; constrained by max width
    const width: number = Math.floor(Math.min(
        (props.widthVW / 100.0) * window.innerWidth,
        props.maxWidth ?? window.innerWidth,
    ));
    const height: number = Math.floor(width / ASPECT_RATIOS[Icon.CART]);

    return <div
        style={{
            position: "relative",
            width: `${width}px`,
            height: `${height}px`,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
    >
        <div style={{
            position: "absolute",
        }}>
            <SVG
                icon={Icon.CART}
                widthVW={props.widthVW}
                maxWidth={props.maxWidth}
                color={props.color}
                hoverColor={props.hoverColor}
            />
        </div>
        <CountTextBox
            textColor={
                isHovered
                    ? props.hoverTextColor ?? props.textColor
                    : props.textColor
            }
            fontSize={Math.floor(height * fontScale)}
        >
            <p>
                <b>{props.count}</b>
            </p>
        </CountTextBox>
    </div>;
}

const CountTextBox = styled.div<{
    textColor: string,
    fontSize: number,
}>`
    position: absolute;
    display: flex;
    justify-content: center;
    // don't prevent icon hover with text layer
    pointer-events: none;
    box-sizing: border-box;

    width: 100%;
    height: 100%;
    // nudge text into opaque cart portion
    padding-top: 5%;
    padding-left: 12%;
    font-size: ${props => props.fontSize}px;

    color: ${props => props.textColor};
`;