import React from "react";
import { Editor } from "./editor";
import { ProtoPrinting } from "../../../../data/ProtoArtwork";

interface Props {
    editor: Editor;
}

type PrintingEntry = {
    width: string;
    height: string;
    price: string;
}

export default function PrintingsArea({ editor }: Props): JSX.Element {
    const [originalEntry, setOriginalEntry] = React.useState<PrintingEntry>({
        width: editor.original.value?.width.toFixed(2) ?? "",
        height: editor.original.value?.height.toFixed(2) ?? "",
        price: editor.original.value?.price.toFixed(2) ?? "",
    });
    const [printingEntry, setPrintingEntry] = React.useState<PrintingEntry>({
        width: "",
        height: "",
        price: "",
    });
    const [aspectRatio, setAspectRatio] = React.useState<number | null>(null);

    // hook for propagating original changes to aspect ratio and form data
    React.useEffect(() => {
        const original = coercePrinting(originalEntry, true);
        if (original) {
            // update editor with new original
            editor.original.update(original);
            // retain aspect ratio from new original
            setAspectRatio(original.width / original.height);
        } else {
            setAspectRatio(null);
        }
    }, [originalEntry]);

    // hook for triggering inferred calculation of printing entry height
    React.useEffect(() => {
        const width = parseFloat(printingEntry.width);
        const height: string = (isNaN(width) || aspectRatio === null)
            ? ""
            : (Math.round((width / aspectRatio) * 100) / 100).toFixed(2)
        setPrintingEntry({
            ...printingEntry,
            height,
        });
    }, [aspectRatio, printingEntry.width]);

    const originalSection = <div
        style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "0.3rem",
        }}
    >
        <label>Original</label>
        <div style={{
            display: "flex",
            gap: "0.3rem",
        }}>
            <input
                type="number"
                placeholder="width"
                value={originalEntry.width}
                onChange={e => setOriginalEntry({
                    ...originalEntry,
                    width: e.target.value,
                })}
            />
            <input
                type="number"
                placeholder="height"
                value={originalEntry.height}
                onChange={e => setOriginalEntry({
                    ...originalEntry,
                    height: e.target.value,
                })}
            />
            <input
                type="number"
                placeholder="price"
                value={originalEntry.price}
                onChange={e => setOriginalEntry({
                    ...originalEntry,
                    price: e.target.value,
                })}
            />
            <div style={{
                // placeholder in place of action buttons above printings
                minWidth: "1.5rem",
            }} />
        </div>
    </div>;

    const completePrintings = editor.printings.value.map((printing, index) => <div
        key={index}
        style={{ width: "100%" }}
    >
        <div style={{
            display: "flex",
            gap: "0.3rem",
        }}>
            <div className="printing-locked-field">
                {printing.width.toFixed(2) + ` "`}
            </div>
            <div className="printing-locked-field">
                {printing.height.toFixed(2) + ` "`}
            </div>
            <div className="printing-locked-field">
                {`$ ` + printing.price.toFixed(2)}
            </div>
            <button
                className="artwork-category-remove-button"
                onClick={() => editor.printings.remove(printing.width)}
            >
                {"x"}
            </button>
        </div>
    </div>);

    const printingsEntrySection = <div style={{
        display: "flex",
        gap: "0.3rem",
    }}>
        <input
            type="number"
            placeholder="width"
            value={printingEntry.width}
            onChange={e => setPrintingEntry({
                ...printingEntry,
                width: e.target.value,
            })}
            style={{ flex: 1 }}
        />
        <div
            className="printing-locked-field"
            style={{
                // use placeholder color when height is NaN
                color: printingEntry.height === "" ? "#a9a9a9" : "var(--teacup-white)",
            }}
        >
            {printingEntry.height === "" ? "height" : `${printingEntry.height} "`}
        </div>
        <input
            type="number"
            placeholder="price"
            value={printingEntry.price}
            onChange={e => setPrintingEntry({
                ...printingEntry,
                price: e.target.value,
            })}
            style={{ flex: 1 }}
        />
        <button
            className="artwork-category-add-button"
            onClick={() => {
                const printing = coercePrinting(printingEntry, false);
                if (
                    printing === null ||
                    editor.printings.value.some(p => p.width === printing.width)
                ) {
                    // if printing is incomplete or duplicate, do not submit
                    return;
                }
                editor.printings.add(printing);
                setPrintingEntry({
                    width: "",
                    height: "",
                    price: "",
                });
            }}
        >
            {"+"}
        </button>
    </div>;

    const printingsSection = <div style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "0.3rem",
    }}>
        <label>Printings</label>
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "1rem",
        }}>
            {completePrintings}
            {
                // only allow printing entry if original is complete
                (aspectRatio !== null && originalEntry.price !== "") && printingsEntrySection
            }
        </div>
    </div>;

    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "100%",
    }}>
        {originalSection}
        {printingsSection}
    </div>;
}

function coercePrinting(
    raw: PrintingEntry,
    isOriginal: boolean = false,
): ProtoPrinting | null {
    const rawWidth = parseFloat(raw.width);
    if (isNaN(rawWidth)) return null;

    const rawHeight = parseFloat(raw.height);
    if (isNaN(rawHeight)) return null;

    const rawPrice = parseFloat(raw.price);
    if (isNaN(rawPrice)) return null;

    return {
        width: Math.round(rawWidth * 100) / 100,
        height: Math.round(rawHeight * 100) / 100,
        price: Math.round(rawPrice * 100) / 100,
        isOriginal,
        /* If this is an unavailable original, user can mark it as such after
         * creation via dedicated action. */
        isAvailable: true,
    };
}