import axios, { AxiosError } from "axios";
import Artwork from "../data/Artwork";
import CheckoutItem from "../data/CheckoutItem";
import { action } from "../redux/store";
import { useAuth } from "../redux/hooks";
import ProtoArtwork from "../data/ProtoArtwork";

type Response<T> =
    | { success: true; data: T }
    | { success: false; errors: string[] };

const CLOUDFLARE_DEV_WORKER = `http://127.0.0.1:8787`;
const CLOUDFLARE_LIVE_WORKER = 'https://teacup-cloudflare.cschot16.workers.dev';

////////////////////////////////////////////////////////////////////////////////
// Helper Functions

let cachedHost: string | null = null;

/**
 * Silently determines whether local development server or live Cloudflare
 * worker should act as server. Strategy here is a good holistic that prevents
 * spamming errors to console while still supporting smooth debugging.
 */
async function genCloudflareURL(): Promise<string> {
    if (cachedHost) return cachedHost;

    /* Checking for localhost is not foolproof as this information is derived
     * from spoofable browser storage. However, unless someone has deliberately
     * made such a change, this is good enough to hide logging from most
     * clients. */
    if (window.location.hostname === 'localhost') {
        /* Try reaching local, development server. This unpreventably logs a
         * warning to console on failure, but this will only be visible if
         * webapp is on localhost. */
        try {
            const response = await axios.post(
                `${CLOUDFLARE_DEV_WORKER}/ping`,
                { message: 'ping' },
            );
            if (response.status === 200 && response.data.message === 'pong') {
                // development server is running, and we are debugging
                cachedHost = CLOUDFLARE_DEV_WORKER;
                console.log('using development server');
                return CLOUDFLARE_DEV_WORKER;
            }
        } catch (_) {
            /* Thrown error means dev server is not running. Fall through to
             * production URL. */
        }
    }

    // either live or not debugging local server; return production URL
    cachedHost = CLOUDFLARE_LIVE_WORKER;
    return CLOUDFLARE_LIVE_WORKER;
}

/**
 * Helper function for wrapping a simple response for ease of use within
 * front-end.
 */
function wrap(success: boolean, onFailure: string): Response<undefined> {
    return success
        ? { success: true, data: undefined }
        : { success: false, errors: [onFailure] };
}

/**
 * Helper wrapper for performing structured Axios requests to server.
 */
async function genRequest<T>({
    method,
    route,
    params = [],
    data = undefined,
    authToken = undefined,
    onFailure = undefined,
}: {
    method: 'GET' | 'POST' | 'DELETE',
    route: string,
    params?: string[],
    data?: any,
    authToken?: string,
    onFailure?: string,
}): Promise<Response<T>> {
    // decide between development and live host
    const host = await genCloudflareURL();

    // construct Axios request
    const request = axios.request({
        url: [host, route, ...params].join('/'),
        method, // ours is a subset of what Axios supports
        data,
        headers: authToken ? { 'X-Auth-Key': authToken } : {},
    });

    const errors: string[] = [];

    try {
        // perform request
        const axiosResponse = await request;

        if (axiosResponse.status === 200) {
            // happy path; return data as expected
            return { success: true, data: axiosResponse.data as T };
        } else {
            const serverErrors: string[] | undefined = axiosResponse.data.errors;
            if (serverErrors === undefined) {
                // bad response without errors means unrecoverable state
                throw axiosResponse;
            } else {
                // graceful failure; record errors for logging and display
                errors.push(...serverErrors);
            }
        }
    } catch (error: any) {
        // do our best to coerce formatted information from errors
        if (error instanceof AxiosError) {
            if (error.response?.data?.errors !== undefined) {
                errors.push(...(error.response.data.errors as string[]));
            }
        }
    }

    if (errors.length < 1) {
        errors.push('unknown request error');
    }

    console.log(`request to ${route.toUpperCase()} failed; errors:`, errors);

    return {
        success: false,
        // prefer using endpoint-specific, displayable error message
        errors: onFailure ? [onFailure] : errors,
    };
}

////////////////////////////////////////////////////////////////////////////////
// Application-Level Functions

/**
 * Retrieve up-to-date Artwork inventory from Cloudflare and push to Redux
 * state. Components observe this update via Redux state hooks.
 */
export async function genUpdateInventory(): Promise<void> {
    const response = await genRequest<{ artworks: Artwork[] }>({
        method: 'GET',
        route: 'list',
    });

    if (response.success) {
        // push Artwork list to Redux
        action.updateInventory(response.data.artworks);
    } else {
        throw new Error('failed to retrieve artwork inventory');
    }
}

////////////////////////////////////////////////////////////////////////////////
// Public API Functions

/**
 * Create checkout session with Stripe. Returns URL for redirect.
 */
async function genCheckout(
    items: CheckoutItem[],
): Promise<Response<{ checkoutURL: string }>> {
    return await genRequest<{ checkoutURL: string }>({
        method: 'POST',
        route: 'checkout',
        data: { items },
        onFailure: 'failed to create Stripe checkout',
    });
}

/**
 * Retrieves Stripe checkout data and returns user email.
 */
async function genReceipt(
    stripeCheckoutID: string,
): Promise<Response<{ email: string }>> {
    return await genRequest<{ email: string }>({
        method: 'GET',
        route: 'receipt',
        params: [stripeCheckoutID],
        onFailure: 'failed to access Stripe checkout',
    });
}

/**
 * Validates the provided auth token against server secret. Successful response
 * indicates a valid token.
 * 
 * If successful, the provided token is stored in Redux state and applied to
 * further privileged requests for this session. Additionally, the token is
 * cached in browser storage for ease of use in future sessions.
 */
async function genAuthenticate(
    authToken: string,
): Promise<Response<undefined>> {
    const response = await genRequest<undefined>({
        method: 'GET',
        route: 'auth',
        authToken, // use user-provided token for validation
        onFailure: 'authentication failed',
    });

    if (response.success) {
        // possibly overwriting stale token
        action.setAuth(authToken);
    }

    return response;
}

////////////////////////////////////////////////////////////////////////////////
// Privileged API Functions

/**
 * Mark an original Printing via its Stripe ID as sold and therefore no longer
 * available. Updates Redux inventory state with new availability returned by
 * the server upon success.
 */
async function genMarkOriginalSold(
    authToken: string,
    stripeID: string,
): Promise<Response<undefined>> {
    const response = await genRequest<{ artworks: Artwork[] }>({
        method: 'GET',
        route: 'original-sold',
        params: [stripeID],
        authToken,
    });

    if (response.success) {
        // push updated inventory to Redux
        action.updateInventory(response.data.artworks);
    }

    return wrap(response.success, 'failed to mark original sold');
}

/**
 * Delete an Artwork as well as all its associated Stripe products and hosted
 * images. Updates Redux inventory state with reduced inventory returned by the
 * server upon success.
 */
async function genDeleteArtwork(
    authToken: string,
    cfID: string,
): Promise<Response<undefined>> {
    const response = await genRequest<{ artworks: Artwork[] }>({
        method: 'GET',
        route: 'delete',
        params: [cfID],
        authToken,
    });

    if (response.success) {
        // push reduced inventory to Redux
        action.updateInventory(response.data.artworks);
    }

    return wrap(response.success, 'failed to delete artwork');
}

/**
 * Prompt server to generate a one-time upload URL within Cloudflare image API.
 * This URL allows the client to directly upload to Cloudflare's image delivery
 * network without exposing API secrets. A placeholder image ID is also
 * returned which becomes permanent upon successful upload.
 * 
 * Note that the returned URL expires after 30 minutes.
 */
async function genImageUploadURL(
    authToken: string,
): Promise<Response<{ uploadURL: string, cfID: string }>> {
    return await genRequest<{ uploadURL: string, cfID: string }>({
        method: 'GET',
        route: 'upload',
        authToken,
    });
}

/**
 * Utilize previously generated Cloudflare image upload URL to upload a locally
 * selected image file.
 */
async function genUploadImage(
    uploadURL: string,
    file: File,
): Promise<Response<{ cfID: string }>> {
    const form = new FormData();
    form.append('file', file);
    // special request handling for client direct communication with Cloudflare
    try {
        const response = await axios.post(uploadURL, form);
        if (response.status === 200 && response.data.success === true) {
            return {
                success: true,
                data: { cfID: response.data.result.id as string },
            };
        }
    } catch (error) {
        console.error('error uploading to Cloudflare', error);
    }
    return { success: false, errors: ['failed to upload image'] };
}

/**
 * Submit a new or edited Artwork object for validation and storage.
 */
async function genCreateArtwork(
    authToken: string,
    artwork: ProtoArtwork,
): Promise<Response<undefined>> {
    const response = await genRequest<{ artworks: Artwork[] }>({
        method: 'POST',
        route: 'create',
        authToken,
        data: { artwork },
    });

    if (response.success) {
        // push increased inventory to Redux
        action.updateInventory(response.data.artworks);
        return { success: true, data: undefined };
    } else {
        return { success: false, errors: [...response.errors] };
    }
}

////////////////////////////////////////////////////////////////////////////////

/**
 * Hook for accessing Cloudflare API from within functional components. Applies
 * stored authentication token to privileged requests.
 */
export function useCloudflare() {
    // default auth to empty string and allow requests to format any throws
    const auth: string = useAuth() ?? '';

    return {
        // public API functions

        genUpdateInventory,
        genCheckout,
        genReceipt,
        genAuthenticate,

        // privileged API functions

        genMarkOriginalSold: (stripeID: string) => genMarkOriginalSold(auth, stripeID),
        genDeleteArtwork: (cfID: string) => genDeleteArtwork(auth, cfID),
        genImageUploadURL: () => genImageUploadURL(auth),
        genUploadImage: (uploadURL: string, file: File) => genUploadImage(uploadURL, file),
        genCreateArtwork: (artwork: ProtoArtwork) => genCreateArtwork(auth, artwork),
    };
}