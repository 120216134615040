import GalleryItem from "./GalleryItem";
import { useInventory } from "../../redux/hooks";
import { Grid } from "../../ditzy/Grid";
import { Box } from "../../ditzy/Box";
import { Flex, FlexColumn } from "../../ditzy/Flex";
import GalleryBanner from "./GalleryBanner";
import GalleryFooter from "./GalleryFooter";

export default function Gallery(): JSX.Element {
	const inventory = useInventory();

	return <FlexColumn gap='10vw'>
		<GalleryBanner />
		<Flex align="center">
			<Box
				paddingX={{
					small: '12vw',
					large: '20vw',
				}}
				maxWidth={1000}
			>
				<Grid
					columns={{
						small: 2,
						large: 3,
					}}
					colGap={{
						small: '6vw',
						large: '4vw',
					}}
					rowGap={{
						small: '8vw',
						large: '4vw',
					}}
				>
					{inventory.map(artwork => <GalleryItem
						key={artwork.name}
						artwork={artwork}
					/>)}
				</Grid>
			</Box>
		</Flex>
		<GalleryFooter />
	</FlexColumn>;
}