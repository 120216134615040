import { useNavigate } from "react-router-dom";
import { action } from "../../../redux/store";

const LEFT_CHEVRON = <span>&#10094;</span>;

export default function LogoutButton(): JSX.Element {
    const navigate = useNavigate();

    return <button
        className="unauth-button"
        onClick={() => {
            // remove auth token from Redux store and local storage
            action.clearAuth();
            // push user back to home page
            navigate('/');
        }}
    >
        {LEFT_CHEVRON} Logout
    </button>;
}