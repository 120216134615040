import { color, xcolor } from "../../constant";
import { useNavigate } from "react-router-dom";
import CartIcon from "./CartIcon";
import React from "react";
import { useCart } from "../../redux/hooks";

import "./cart.css";

interface Props {
    widthVW: number;
    maxWidth?: number;
    forceHide?: boolean;
}

export default function CartLink({
    widthVW,
    maxWidth,
    forceHide = false,
}: Props): JSX.Element {
    // just show empty cart icon if not yet loaded
    const cart = useCart() ?? [];
    const navigate = useNavigate();
    const animationRef = React.createRef<HTMLDivElement>();

    const cartSize = cart.reduce((volume, item) => volume + item.quantity, 0);

    const [hidden, setHidden] = React.useState<boolean>(
        forceHide || cartSize < 1,
    );

    /* Only detect falling edge of should hide (triggered by first item added to
     * the cart) and add fade in animation at that time. */
    React.useEffect(
        () => setHidden(prevHidden => {
            if (prevHidden && cartSize > 0 && !forceHide) {
                animationRef.current?.classList.add("fade-in");
                return false;
            } else {
                // do nothing
                return prevHidden;
            }
        }),
        [cart, forceHide, animationRef],
    );

    const icon = <CartIcon
        count={cartSize}
        widthVW={widthVW}
        maxWidth={maxWidth}
        textColor={xcolor.light_blue}
        hoverTextColor={xcolor.white}
        color={color.WHITE}
        hoverColor={color.LIGHT_BLUE}
    />;

    const width: number = Math.floor(Math.min(
        (widthVW / 100.0) * window.innerWidth,
        maxWidth ?? window.innerWidth,
    ));

    return <button
        style={{
            border: "none",
            padding: "0px",
            backgroundColor: "inherit",
            cursor: "pointer",
            // occupy constant horizontal space, even whilst hidden
            width: `${width}px`,
            // if hidden, do not interact with mouse
            pointerEvents: hidden ? "none" : "all",
        }}
        onClick={() => {
            // only navigate to cart if not hidden
            if (!hidden) {
                navigate("/cart");
            }
        }}
    >
        <div ref={animationRef}>
            {hidden ? null : icon}
        </div>
    </button>;
}