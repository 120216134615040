import { CF_ACCOUNT_HASH } from "./constant";
import Artwork from "./data/Artwork";

function getImageURL(artwork: Artwork | string): string {
    const cfID = typeof artwork === "string" ? artwork : artwork.cfID;
    return `https://imagedelivery.net/${CF_ACCOUNT_HASH}/${cfID}/public`;
}

function getThumbnailURL(artwork: Artwork | string): string {
    const cfID = typeof artwork === "string" ? artwork : artwork.cfID;
    return `https://imagedelivery.net/${CF_ACCOUNT_HASH}/${cfID}/thumbnail`;
}

function randomHexString(length: number): string {
    const hex = "0123456789abcdef";
    let str = "";
    for (let i = 0; i < length; i++) {
        str = str.concat(hex[Math.floor(Math.random() * hex.length)]);
    }
    return str;
}

export const util = {
    getImageURL,
    getThumbnailURL,
    randomHexString,
};