import styled from "styled-components";
import { color, xcolor } from "../../constant";
import Navigation from "../common/Navigation";
import SVG, { Icon } from "../common/SVG";
import { useState } from "react";
import { fontSize, token } from "../../constant";
import { useCart } from "../../redux/hooks";
import { util } from "../../util";
import { action } from "../../redux/store";
import { useCloudflare } from "../../api/Cloudflare";

const SHIPPING_MSG = "Each print is made to order. Please allow up to two weeks to receive your art.";

export default function CartPage(): JSX.Element {
    return <div
        className="flex-column"
        style={{
            alignItems: "center",
            width: "100vw",
            minHeight: "100vh",
        }}
    >
        <Navigation
            returnURL={"/"}
            returnText={"Return to Gallery"}
            hideCartLink={true}
        />
        <CartArea>
            <CartContent />
        </CartArea>
    </div>;
}

const CartArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: clamp(0px, 80vw, 900px);
    flex-grow: 1;
    font-size: ${fontSize.body};
    padding-top: 12vh;
`;

function CartContent(): JSX.Element {
    const [processing, setProcessing] = useState<boolean>(false);
    const cart = useCart();
    const cloudflare = useCloudflare();

    if (cart.length < 1) {
        return <div className="flex-column">
            <p>
                Your Cart is Empty
            </p>
        </div>;
    }

    const hasAnyMultiples: boolean = cart.find(
        item => item.quantity > 1,
    ) !== undefined;

    const items = cart.map((item, index) => {
        const quantity = <p style={{
            width: "6%", // consume consistent width
            textAlign: "center",
        }}>
            {item.quantity}{token.times}
        </p>;

        const original = item.printing.isOriginal ? <span>
            {" "}{token.bull}{" "}Original
        </span> : "";

        const dimensions = <p style={{
            color: xcolor.off_white,
        }}>
            {item.printing.width.toFixed(0)}{token.times}{item.printing.height.toFixed(0)}"{original}
        </p>;

        const price = <p>
            ${(item.quantity * item.printing.price).toFixed(2)}
        </p>;

        return <CartItem key={index}>
            <div style={{
                height: "80%",
                aspectRatio: "1.0",
                display: "flex",
                justifyContent: "center",
            }}>
                <img
                    src={util.getThumbnailURL(item.artwork)}
                    style={{
                        height: "100%",
                    }}
                    alt={`${item.artwork.name} preview`}
                />
            </div>
            {hasAnyMultiples ? quantity : null}
            <div
                className="flex-column"
                style={{
                    justifyContent: "center",
                    height: "100%",
                    gap: "8%",
                    flexGrow: "1",
                }}
            >
                <p>{item.artwork.name}</p>
                {dimensions}
            </div>
            {price}
            <TrashButton
                onClick={() => action.removeItemsFromCart(item.stripeID)}
            >
                <SVG
                    icon={Icon.TRASH}
                    widthVW={5.0}
                    maxWidth={24}
                    color={color.LIGHT_GRAY}
                    hoverColor={color.OFF_WHITE}
                />
            </TrashButton>
        </CartItem>;
    });

    const lineTotal: number = cart.reduce((sum, item) => {
        return sum += item.quantity * item.printing.price;
    }, 0.0);

    const total = <CartItem key="total">
        <div style={{
            height: "80%",
            aspectRatio: "1.0",
        }} />
        {hasAnyMultiples ? <div style={{ width: "6%" }} /> : null}
        <p style={{ flexGrow: "1" }}>Total</p>
        <p>${lineTotal.toFixed(2)}</p>
        <div style={{
            width: "clamp(0px, 5vw, 24px)",
        }} />
    </CartItem>;

    return <div
        className="flex-column"
        style={{
            width: "100%",
            alignItems: "center",
            gap: "3vh",
        }}
    >
        <div
            className="flex-column"
            style={{
                width: "100%",
            }}
        >
            {items}
            {total}
        </div>
        <p style={{
            maxWidth: "clamp(0px, 80%, 500px)",
            color: xcolor.off_white,
            textAlign: "center",
        }}>
            {SHIPPING_MSG}
        </p>
        <CheckoutButton onClick={() => {
            setProcessing(true);
            cloudflare.genCheckout(cart.map(item => ({
                // TODO awkward step back here
                stripeID: item.stripeID,
                quantity: item.quantity,
            }))).then(response => {
                if (response.success) {
                    window.location.replace(response.data.checkoutURL);
                } else {
                    throw new Error('failed to create checkout');
                }
            });
        }}>
            {processing
                ? <p>
                    Creating Checkout...
                </p>
                : <>
                    <p>
                        Checkout with
                    </p>
                    <SVG
                        icon={Icon.STRIPE}
                        widthVW={10}
                        maxWidth={50}
                        color={color.WHITE}
                    />
                </>
            }
        </CheckoutButton>
    </div>;
}

const CartItem = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;

    width: 100%;
    height: 6vh;
    padding-right: 2%;
    gap: 2%;
`;

const TrashButton = styled.button`
    border: none;
    padding: 0px;

    background-color: inherit;

    &:hover {
        cursor: pointer;
    }
`;

const CheckoutButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: none;

    width: clamp(160px, 50vw, 320px);
    height: clamp(0px, 13.2vw, 66px);
    gap: clamp(0px, 1.6vw, 8px);

    background-color: ${xcolor.light_blue};

    &:hover {
        cursor: pointer;
        background-color: ${xcolor.blue};
    }
`;