import Artwork from "../data/Artwork";
import Printing from "../data/Printing";

export enum TeacupActionType {
    ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART',
    REMOVE_ITEMS_FROM_CART = 'REMOVE_ITEMS_FROM_CART',
    EMPTY_CART = 'EMPTY_CART',
    UPDATE_INVENTORY = 'UPDATE_INVENTORY',
    SET_AUTH_TOKEN = 'SET_AUTH_TOKEN',
}

interface AddItemAction {
    type: TeacupActionType.ADD_ITEM_TO_CART;
    printing: Printing;
}

interface RemoveItemsAction {
    type: TeacupActionType.REMOVE_ITEMS_FROM_CART;
    stripeID: string;
}

interface EmptyCartAction {
    type: TeacupActionType.EMPTY_CART;
}

interface UpdateInventoryAction {
    type: TeacupActionType.UPDATE_INVENTORY;
    inventory: Artwork[];
}

interface SetAuthTokenAction {
    type: TeacupActionType.SET_AUTH_TOKEN;
    // allow undefined for clearing auth, i.e. logout
    authToken: string | undefined;
}

export type TeacupAction =
    | AddItemAction
    | RemoveItemsAction
    | EmptyCartAction
    | UpdateInventoryAction
    | SetAuthTokenAction;