import Artwork from "../../data/Artwork";
import { util } from "../../util";
import { Flex, FlexColumn } from "../../ditzy/Flex";
import { Box } from "../../ditzy/Box";
import { Image } from "../../ditzy/Image";
import { Text } from "../../ditzy/Text";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface GalleryItemProps {
    artwork: Artwork;
}

export default function GalleryItem({ artwork }: GalleryItemProps): JSX.Element {
    return <Box>
        <ItemLink to={`/showcase/${artwork.cfID}`}>
            <FlexColumn
                gap={{
                    small: '4vw',
                    large: '2vw',
                }}
            >
                <Polaroid>
                    <Flex
                        fullHeight
                        align="center"
                    >
                        <Box width="80%">
                            <Image
                                src={util.getImageURL(artwork)}
                                present="square-crop"
                            />
                        </Box>
                    </Flex>
                </Polaroid>
                <Text
                    text={artwork.name}
                    centerJustify
                    italic
                    size={{
                        small: '1.0rem',
                        medium: '0.9rem',
                        large: '1.4rem',
                    }}
                />
            </FlexColumn>
        </ItemLink>
    </Box>;
}

const Polaroid = styled.div`
    width: 100%;
    aspect-ratio: 1.0;
    background-color: var(--teacup-white);
`;

const ItemLink = styled(Link)`
    color: var(--teacup-white);
    text-decoration: none;

    &:hover {
        color: var(--teacup-light-blue);
    }
`;