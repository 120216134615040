import Artwork from "../data/Artwork";
import CheckoutItem from "../data/CheckoutItem";
import Printing from "../data/Printing";
import { TeacupAction, TeacupActionType } from "./actions";
import { initFromCache, writeToCache } from "./cache";
import { State } from "./store";

export function teacupReducer(
    // use cached session from browser storage if available
    state: State = initFromCache(),
    action: TeacupAction,
): State {
    // default state for Redux initialization
    let newState: State = state;

    // NOTE: each action must return a new object for Redux
    switch (action.type) {
        case TeacupActionType.UPDATE_INVENTORY:
            newState = {
                ...state,
                cart: filterExpiredItems(state, action.inventory),
                inventory: action.inventory,
            };
            break;
        case TeacupActionType.ADD_ITEM_TO_CART:
            newState = {
                ...state,
                cart: addItemToCart(state, action.printing),
            };
            break;
        case TeacupActionType.REMOVE_ITEMS_FROM_CART:
            newState = {
                ...state,
                cart: removeItemsFromCart(state, action.stripeID),
            };
            break;
        case TeacupActionType.EMPTY_CART:
            newState = {
                ...state,
                cart: [],
            };
            break;
        case TeacupActionType.SET_AUTH_TOKEN:
            newState = {
                ...state,
                authToken: action.authToken,
            };
            break;
    }

    // cache cart contents and auth token to local storage
    writeToCache(newState);

    return newState;
}

////////////////////////////////////////////////////////////////////////////////
// Action Handlers

function filterExpiredItems(
    state: State,
    inventory: Artwork[],
): CheckoutItem[] {
    const availableIDs: string[] = inventory.reduce(
        (ids: string[], artwork) => ids.concat(
            artwork.printings.map(printing => printing.stripeID),
        ), [],
    );
    // note that filter returns a new array object
    return state.cart.filter(cartItem => availableIDs.includes(cartItem.stripeID));
}

function addItemToCart(
    state: State,
    addition: Printing,
): CheckoutItem[] {
    const alreadyInCart: CheckoutItem | undefined = state.cart.find(
        cartItem => cartItem.stripeID === addition.stripeID,
    );

    if (addition.isOriginal && alreadyInCart) {
        throw new Error('cannot buy more than one original');
    }
    if (addition.isOriginal && !addition.isAvailable) {
        throw new Error('original already sold');
    }

    // concat if not in cart already, otherwise increment quantity
    if (alreadyInCart) {
        return state.cart.map(cartItem =>
            cartItem.stripeID === addition.stripeID
                ? { ...cartItem, quantity: cartItem.quantity + 1 }
                : cartItem
        );
    } else {
        return state.cart.concat({ stripeID: addition.stripeID, quantity: 1 });
    }
}

function removeItemsFromCart(
    state: State,
    stripeID: string,
): CheckoutItem[] {
    return state.cart.filter(cartItem => cartItem.stripeID !== stripeID);
}