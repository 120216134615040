import styled from "styled-components";
import Artwork from "../../data/Artwork";
import Printing from "../../data/Printing";
import { color, xcolor } from "../../constant";
import SVG, { Icon } from "../common/SVG";
import CartIcon from "../cart/CartIcon";
import React from "react";
import "./showcase.css";
import { fontSize, token } from "../../constant";
import { useCart } from "../../redux/hooks";
import { action } from "../../redux/store";

interface Props {
    artwork: Artwork;
    options: Printing[];
}

export default function BuyPrintingsButton({
    artwork,
    options,
}: Props): JSX.Element {
    const printingOptions = options
        // sort options by ascending size
        .sort((a, b) => a.width - b.width)
        .map((printing, index) => <PrintingOption
            artwork={artwork}
            printing={printing}
            key={`${index}`}
        />);

    return <div style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
    }}>
        <Header>
            <p>Buy a Print</p>
        </Header>
        <Options>
            {printingOptions}
        </Options>
    </div>;
}

const Header = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;

    width: 100%;
    aspect-ratio: 7.0;
    padding-left: 3%;
    padding-right: 3%;
    font-size: ${fontSize.subheader};

    // use a border just so we scale identically with original div
    border-width: clamp(4px, 0.5vw, 6px);
    border-style: solid;
    border-color: ${xcolor.light_blue};

    background-color: ${xcolor.light_blue};
`;

const Options = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    width: 100%;
    gap: clamp(4px, 0.5vw, 6px);
    font-size: ${fontSize.body};

    border-width: clamp(4px, 0.5vw, 6px);
    border-top: 0px;
    border-style: solid;
    border-color: ${xcolor.light_blue};

    background-color: ${xcolor.light_blue};
`;

interface PrintingOptionProps {
    artwork: Artwork;
    printing: Printing;
}

function PrintingOption({
    artwork,
    printing,
}: PrintingOptionProps): JSX.Element {
    const cart = useCart();
    const animationRef = React.createRef<HTMLDivElement>();

    const [inCartOnLoad] = React.useState<boolean>(
        () => cart.find(item => item.stripeID === printing.stripeID) !== undefined,
    );

    const dimensions = <p>
        {printing.width.toFixed(1)} {token.times} {printing.height.toFixed(1)}"
    </p>;

    const quantityInCart = cart.find(item => item.stripeID === printing.stripeID)?.quantity ?? 0;

    /* Have icon for adding to cart and showing quantity in cart simultaneously
     * rendered so there is no flickering of SVG render on add to cart. */
    const icon = <div style={{
        position: "relative",
        width: "4vw",
        maxWidth: "32px",
        height: "100%",
    }}>
        <div style={{
            display: quantityInCart < 1 ? "flex" : "none",
        }}>
            <SVG
                icon={Icon.ADD_TO_CART}
                widthVW={4}
                maxWidth={32}
                color={color.WHITE}
            />
        </div>
        <div style={{
            display: quantityInCart > 0 ? "flex" : "none",
        }}>
            <CartIcon
                count={quantityInCart}
                textColor={xcolor.pink}
                widthVW={4}
                maxWidth={32}
                color={color.WHITE}
            />
        </div>
    </div>;

    return <div
        style={{
            position: "relative",
            overflow: "hidden",
            width: "100%",
            aspectRatio: "7.0",
            backgroundColor: xcolor.pink,
        }}
    >
        <div
            ref={animationRef}
            style={{
                position: "absolute",
                width: "100%",
                aspectRatio: "7.0",
                pointerEvents: "none",
                backgroundColor: xcolor.blue,
                display: inCartOnLoad ? "none" : "all",
            }}
        />
        <Option
            onClick={() => {
                if (quantityInCart < 1) {
                    /* On first cart addition of this printing size, transition
                     * background color. */
                    animationRef.current?.classList.add("slide-out");
                }
                action.addItemToCart(printing);
            }}
        >
            {dimensions}
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "clamp(0px, 2vw, 12px)",
            }}>
                <p style={{
                    color: xcolor.white,
                }}>
                    ${printing.price.toFixed(2)}
                </p>
                {icon}
            </div>
        </Option>
    </div >;
}

const Option = styled.button`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    width: 100%;
    aspect-ratio: 7.0;
    padding-left: 3%;
    padding-right: 3%;

    background-color: transparent;

    border-width: clamp(4px, 0.5vw, 6px);
    border-style: solid;
    border-color: transparent;

    &:hover {
        border-color: ${xcolor.white};
        cursor: pointer;
    }
`;