import React from "react";
import styled from "styled-components";

export enum Icon {
    ADD_TO_CART = "add-to-cart",
    CART = "cart",
    INSTAGRAM = "instagram",
    SIGNATURE = "signature",
    TRASH = "trash",
    LEFT_WEDGE = "left-wedge",
    STRIPE = "stripe",
    TIKTOK = "tiktok",
}

/**
 * Aspect ratio is width / height.
 */
export const ASPECT_RATIOS: { [key in Icon]: number } = {
    "add-to-cart": 1.125,
    "cart": 1.125,
    "instagram": 0.875,
    "signature": 6.246,
    "trash": 0.875,
    "left-wedge": 0.625,
    "stripe": 1.125,
    "tiktok": 0.875,
};

interface Props {
    icon: Icon;
    /**
     * Width to size this SVG icon to. As a fraction of screen width from 0 to
     * 100 floating.
     */
    widthVW: number;
    /**
     * Max width in pixels to allow SVG scaling to. If undefined, no maximum is
     * applied.
     */
    maxWidth?: number;
    /**
     * Color to apply when this icon is not hovered.
     */
    color: string;
    /**
     * Color to apply while this icon is being hovered. If undefined, normal
     * color is used and no change occurs on hover.
     */
    hoverColor?: string;
}

export default function SVG(props: Props): JSX.Element {
    /* In order to handle icon scaling seamlessly, we must observe changes to
     * window dimensions. This is because SVG only works with static dimensions
     * and CSS does not know when to trigger re-renders. */
    const [screenWidth, setScreenWidth] = React.useState<number>(
        window.innerWidth,
    );
    React.useEffect(() => {
        window.addEventListener(
            `resize`,
            () => setScreenWidth(window.innerWidth),
        );
    }, []);

    const width = Math.floor(Math.min(
        props.maxWidth ?? screenWidth,
        (props.widthVW / 100.0) * screenWidth,
    ));
    const height = Math.floor(width / ASPECT_RATIOS[props.icon]);

    const filename = `/icons/${props.icon}.svg#${props.icon}-svg`;

    /* We embed SVG file into svg element with extra steps here so we can
     * perform intermediate step of applying our CCS rules to the injected SVG,
     * allowing us to recolor. This is pretty clunky, but the best we can do
     * without webpack. */
    return <ColoredSVG
        width={width}
        height={height}
        color={props.color}
        hoverColor={props.hoverColor ?? props.color}
    >
        <use xlinkHref={filename} />
    </ColoredSVG>;
}

const ColoredSVG = styled.svg<{ color: string, hoverColor: string }>`
    // only child is SVG embed
    > * {
        --color: var(${props => props.color});
    }

    /* When hovered, change child's color. Cannot use embedded SVG for hover
     * selection as then space between path will not trigger. */
    &:hover {
        > * {
            --color: var(${props => props.hoverColor});
        }
    }
`;