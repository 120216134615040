import styled from "styled-components";
import Artwork from "../../data/Artwork";
import Printing from "../../data/Printing";
import { color, xcolor } from "../../constant";
import SVG, { Icon } from "../common/SVG";
import React from "react";
import { fontSize } from "../../constant";
import { useCart } from "../../redux/hooks";
import { action } from "../../redux/store";
import "./showcase.css";

interface Props {
    artwork: Artwork;
    original: Printing;
}

export default function BuyOriginalButton({
    artwork,
    original,
}: Props): JSX.Element {
    const cart = useCart();
    const animationRef = React.createRef<HTMLDivElement>();
    /* Check once on first load to see if this original is already in the cart.
     * We don't want to do this on every render otherwise we'll interupt our
     * nice animations. */
    const [inCart] = React.useState<boolean>(
        () => cart.find(item => item.stripeID === original.stripeID) !== undefined,
    );

    if (!original.isAvailable) {
        return <SoldButton>
            Original Sold
        </SoldButton>;
    }

    const availableButton = inCart ? null : <AvailableButton onClick={() => {
        animationRef.current?.classList.add("slide-out");
        action.addItemToCart(original);
    }}>
        <p>Buy the Original</p>
        <div style={{
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            gap: "clamp(0px, 2vw, 12px)",
        }}>
            <p>${original.price.toFixed(2)}</p>
            <SVG
                icon={Icon.ADD_TO_CART}
                widthVW={6.0}
                maxWidth={40}
                color={color.WHITE}
            />
        </div>
    </AvailableButton >;

    return <InCart>
        <p>Original in Cart</p>
        <div ref={animationRef} style={{
            position: "absolute",
            width: "100%",
        }}>
            {availableButton}
        </div>
    </InCart>;
}

const Button = styled.button`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: none;

    width: 100%;
    aspect-ratio: 7.0;
    padding-left: 3%;
    padding-right: 3%;
    font-size: ${fontSize.subheader};
`;

const InCart = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;

    width: 100%;
    aspect-ratio: 7.0;
    padding-left: 3%;
    padding-right: 3%;

    background-color: ${xcolor.pink};
`;

const AvailableButton = styled(Button)`
    justify-content: space-between;
    box-sizing: border-box;

    background-color: ${xcolor.light_blue};

    border-width: clamp(4px, 0.5vw, 6px);
    border-style: solid;
    border-color: ${xcolor.light_blue};

    &:hover {
        border-color: ${xcolor.white};
        cursor: pointer;
    }
`;

const SoldButton = styled(Button)`
    justify-content: center;

    color: ${xcolor.off_white};
    background-color: ${xcolor.light_gray};
`;