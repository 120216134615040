import { Link } from "react-router-dom";
import styled from "styled-components";
import { color, xcolor } from "../../constant";
import CartLink from "../cart/CartLink";
import { fontSize } from "../../constant";
import SVG, { Icon } from "./SVG";
import React from "react";

interface Props {
    returnURL: string;
    returnText: string;
    hideCartLink?: boolean;
}

export default function Navigation({
    returnURL,
    returnText,
    hideCartLink = false,
}: Props): JSX.Element {
    // manually track hover event to propagate into SVG
    const [isLinkHovered, setIsLinkHovered] = React.useState<boolean>(false);

    return <Bar>
        <Return
            to={returnURL}
            onMouseEnter={() => setIsLinkHovered(true)}
            onMouseLeave={() => setIsLinkHovered(false)}
        >
            <div style={{
                // have to nudge slightly to align with text
                paddingTop: "1%",
            }}>
                <SVG
                    icon={Icon.LEFT_WEDGE}
                    widthVW={3.2}
                    maxWidth={16}
                    color={isLinkHovered ? color.LIGHT_BLUE : color.WHITE}
                />
            </div>
            {returnText}
        </Return>
        <CartLink
            widthVW={9}
            maxWidth={45}
            forceHide={hideCartLink}
        />
    </Bar>;
}

const Bar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    width: 100%;
    height: clamp(0px, 10vh, 100px);
    font-size: ${fontSize.subheader};
    padding-left: clamp(0px, 4vw, 32px);
    padding-right: clamp(0px, 7vw, 56px);
`;

const Return = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;

    gap: clamp(0px, 2vw, 16px);

    color: ${xcolor.white};

    &:hover {
        color: ${xcolor.light_blue};
    }
`;