// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.portrait-only {
    @media (orientation: landscape) {
        display: none;
    }
}

.landscape-only {
    @media (orientation: portrait) {
        display: none;
    }
}

.clipboard-button {
    border: none;
    background-color: inherit;
    padding: 0px;
    color: var(--teacup-white);
    text-decoration: underline;
    cursor: copy;
}`, "",{"version":3,"sources":["webpack://./src/teacup.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,0BAA0B;IAC1B,0BAA0B;IAC1B,YAAY;AAChB","sourcesContent":[".flex-row {\n    display: flex;\n    flex-direction: row;\n}\n\n.flex-column {\n    display: flex;\n    flex-direction: column;\n}\n\n.portrait-only {\n    @media (orientation: landscape) {\n        display: none;\n    }\n}\n\n.landscape-only {\n    @media (orientation: portrait) {\n        display: none;\n    }\n}\n\n.clipboard-button {\n    border: none;\n    background-color: inherit;\n    padding: 0px;\n    color: var(--teacup-white);\n    text-decoration: underline;\n    cursor: copy;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
