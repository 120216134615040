import { configureStore } from "@reduxjs/toolkit";
import Artwork from "../data/Artwork";
import CheckoutItem from "../data/CheckoutItem";
import { TeacupAction, TeacupActionType } from "./actions";
import { teacupReducer } from "./reducer";
import Printing from "../data/Printing";

export interface State {
    inventory: Artwork[];
    cart: CheckoutItem[];
    authToken: string | undefined;
}

export const store = configureStore<State, TeacupAction>({
    reducer: teacupReducer,
});

/**
 * Add a single instance of the Stripe product specified by the provided Stripe
 * ID to the cart if possible.
 */
function addItemToCart(printing: Printing): void {
    const action: TeacupAction = {
        type: TeacupActionType.ADD_ITEM_TO_CART,
        printing,
    };
    store.dispatch(action);
}

/**
 * Remove all instances of the Stripe product specified by the provided Stripe
 * ID from the cart.
 */
function removeItemsFromCart(stripeID: string): void {
    const action: TeacupAction = {
        type: TeacupActionType.REMOVE_ITEMS_FROM_CART,
        stripeID,
    };
    store.dispatch(action);
}

/**
 * Remove all items from the cart.
 */
function emptyCart(): void {
    const action: TeacupAction = {
        type: TeacupActionType.EMPTY_CART,
    };
    store.dispatch(action);
}

/**
 * Update Redux state and all dependent components with a new list of available
 * Artworks. Also updates user cart contents, removing any items which are no
 * longer available.
 */
function updateInventory(inventory: Artwork[]): void {
    const action: TeacupAction = {
        type: TeacupActionType.UPDATE_INVENTORY,
        inventory,
    };
    store.dispatch(action);
}

function setAuth(authToken: string): void {
    const action: TeacupAction = {
        type: TeacupActionType.SET_AUTH_TOKEN,
        authToken,
    };
    store.dispatch(action);
}

function clearAuth(): void {
    const action: TeacupAction = {
        type: TeacupActionType.SET_AUTH_TOKEN,
        authToken: undefined,
    };
    store.dispatch(action);
}

export const action = {
    addItemToCart,
    removeItemsFromCart,
    emptyCart,
    updateInventory,
    setAuth,
    clearAuth,
};