import styled from "styled-components";

type Presentation = (
    | 'full'
    | 'square-crop'
);

interface Props {
    src: string;
    present?: Presentation;
}

export function Image(props: Props): JSX.Element {
    switch (props.present ?? 'full') {
        case "full":
            return <DitzyImageFull
                src={props.src}
            />;
        case "square-crop":
            return <DitzyImageSquareCrop
                src={props.src}
            />;
    }
}

const DitzyImageFull = styled.img`
    width: 100%;
    height: auto;
`;

const DitzyImageSquareCrop = styled.img`
    object-fit: cover;
    width: 100%;
    aspect-ratio: 1.0;
`;