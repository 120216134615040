import styled from "styled-components";
import Navigation from "../common/Navigation";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { action } from "../../redux/store";
import { useCloudflare } from "../../api/Cloudflare";

export default function PurchaseSuccessPage(): JSX.Element {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const cloudflare = useCloudflare();
    const [email, setEmail] = useState<string | null>(null);

    useEffect(() => {
        const stripeCheckoutID = params.get("session_id");
        if (stripeCheckoutID === null) {
            /* Page should not be accessed except in the context of a successful
             * transaction. In other cases, redirect user to gallery. */
            navigate(`/`);
            return;
        }
        try {
            cloudflare.genReceipt(stripeCheckoutID).then(response => {
                // TODO inform user on failure
                if (response.success) {
                    setEmail(response.data.email);
                    // empty cart of items which have just been purchased
                    action.emptyCart();
                    // update inventory in Redux state to reflect our purchase
                    cloudflare.genUpdateInventory();
                }
            });
        } catch (_) {
            throw new Error("failed to retrieve checkout");
        }
    }, [navigate, params]);

    const status = email === null
        ? "Sending you a receipt email..."
        : `A receipt has been sent to ${email}`;

    return <PageContainer>
        <Navigation
            returnURL="/"
            returnText="Return to Gallery"
            hideCartLink={true}
        />
        <BodyContainer>
            Thank you for you purchase!
            <br /><br />
            {status}
        </BodyContainer>
    </PageContainer>;
}

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
`;

const BodyContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    flex-grow: 1;
`;