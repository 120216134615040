import LogoutButton from "./auth/LogoutButton";
import { useAuth } from "../../redux/hooks";
import AuthDialog from "./auth/AuthDialog";
import InventoryTable from "./table/InventoryTable";
import { usePopover } from "./popover/Popover";
import "./inventory.css";

export default function Inventory(): JSX.Element {
    const auth = useAuth();
    const [control, getActivePopover] = usePopover();
    const activePopover = getActivePopover();

    return <div className="inv-container">
        {(auth === null) && <AuthDialog />}
        {auth && <LogoutButton />}
        {auth && <InventoryTable control={control} />}
        {activePopover && (
            <div className="popover-layer">
                {activePopover}
            </div>
        )}
    </div>;
}