// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-out {
    animation-name: slide-out;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    /* Don't allow user to click button again while animation is ongoing or
     * complete otherwise we'll get undesirable behavior. */
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
}

@keyframes slide-out {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
        /* Looks a little nicer when we don't go all the way to zero. */
        opacity: 0.25;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/showcase/showcase.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,4BAA4B;IAC5B,6BAA6B;;IAE7B;2DACuD;IACvD,oBAAoB;IACpB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI;QACI,wBAAwB;IAC5B;;IAEA;QACI,4BAA4B;QAC5B,+DAA+D;QAC/D,aAAa;IACjB;AACJ","sourcesContent":[".slide-out {\n    animation-name: slide-out;\n    animation-duration: 1s;\n    animation-iteration-count: 1;\n    animation-fill-mode: forwards;\n\n    /* Don't allow user to click button again while animation is ongoing or\n     * complete otherwise we'll get undesirable behavior. */\n    pointer-events: none;\n    user-select: none;\n}\n\n@keyframes slide-out {\n    0% {\n        transform: translateX(0);\n    }\n\n    100% {\n        transform: translateX(-100%);\n        /* Looks a little nicer when we don't go all the way to zero. */\n        opacity: 0.25;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
