import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Gallery from "./components/gallery/Gallery";
import Showcase from "./components/showcase/Showcase";
import CartPage from "./components/cart/CartPage";
import PurchaseSuccessPage from "./components/receipt/PurchaseSuccessPage";
import Inventory from "./components/inventory/Inventory";
import React from "react";
import { genUpdateInventory } from "./api/Cloudflare";
import "./teacup.css";

export default function Teacup(): JSX.Element {
	// update inventory content on first load
	React.useEffect(() => { genUpdateInventory() }, []);

	return <Provider store={store}>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Gallery />} />
				<Route path="/showcase" element={<Showcase />} />
				<Route path="/showcase/:id" element={<Showcase />} />
				<Route path="/cart" element={<CartPage />} />
				<Route path="/success" element={<PurchaseSuccessPage />} />
				<Route path="/inventory" element={<Inventory />} />
			</Routes>
		</BrowserRouter>
	</Provider>;
}