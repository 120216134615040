import Artwork from "../../../data/Artwork";
import Printing from "../../../data/Printing";
import { bull } from "../const";

export default function PrintingsCell({ artwork }: { artwork: Artwork }): JSX.Element {
    // all Artworks have an original
    const original = artwork.printings.find(p => p.isOriginal)!;
    // sort non-original printings by increasing size for ease of reading
    const prints = artwork.printings.filter(p => !p.isOriginal).sort((a, b) => a.width - b.width);

    return <div className="inv-printings-cell">
        <p key='original'>{bull} {`${formatSize(original)} (original)`}</p>
        {prints.map((printing, index) => <p key={index}>{bull} {`${formatSize(printing)}`}</p>)}
    </div>;
}

function formatSize(printing: Printing): string {
    return `${printing.width} x ${printing.height}"`;
}