import Artwork from "../../../data/Artwork";
import { util } from "../../../util";
import ActionsCell from "./ActionsCell";
import { bull, headers } from "../const";
import PrintingsCell from "./PrintingsCell";
import { PopoverControl } from "../popover/Popover";

interface Props {
    artwork: Artwork;
    control: PopoverControl;
}

export default function InventoryTableRow(
    { artwork, control }: Props,
): JSX.Element {
    const datums: JSX.Element[] = [
        <img src={util.getThumbnailURL(artwork)} style={{ width: '100%' }} />,
        <div className="inv-details-cell">
            <p>{artwork.name}</p>
            <p>{bull} {artwork.medium}</p>
            <p>{bull} {artwork.publishDate}</p>
        </div>,
        <p>{artwork.description}</p>,
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.3rem",
        }}>
            {artwork.categories.map(cat => <p key={cat}>{`- ${cat}`}</p>)}
        </div>,
        <p>{artwork.priority}</p>,
        <PrintingsCell artwork={artwork} />,
        <ActionsCell artwork={artwork} control={control} />,
    ];

    return <div className="inv-tr">
        {datums.map((datum, index) => <div
            key={index}
            className="inv-cell"
            style={{ width: `${headers[index][1]}%` }}
        >
            {datum}
        </div>)}
    </div>;
}