import { DynamicRule } from "./DynamicRule";

export function useDynamicStyling(...rules: DynamicRule<any>[]): string {
    if (rules.length < 1) {
        return '';
    } else if (rules.length === 1) {
        return rules[0].resolve();
    } else {
        return DynamicRule.combine(rules).resolve();
    }
}