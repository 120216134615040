import React from "react";
import { Editor } from "./editor";

interface Props {
    editor: Editor;
}

export default function CategoriesArea({ editor }: Props): JSX.Element {
    const [entry, setEntry] = React.useState<string>("");

    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.3rem",
        width: "100%",
    }}>
        <div style={{
            display: "flex",
            width: "100%",
            gap: "0.3rem",
        }}>
            <input
                type="text"
                value={entry}
                onChange={e => setEntry(e.target.value)}
                style={{
                    width: "100%",
                }}
            />
            <button
                onClick={() => {
                    editor.categories.add(entry.trim().toLowerCase());
                    setEntry("");
                }}
                className="artwork-category-add-button"
            >
                {"+"}
            </button>
        </div>
        {editor.categories.value.map((category, index) => <div
            key={index}
            style={{
                display: "flex",
                height: "1.5rem",
                gap: "0.3rem",
            }}
        >
            <button
                onClick={() => editor.categories.remove(category)}
                className="artwork-category-remove-button"
            >
                {"x"}
            </button>
            <div style={{
                display: "flex",
                alignItems: "center",
                height: "1.5rem",
            }}>
                {category}
            </div>
        </div>)}
    </div>;
}