import { xcolor } from "../../constant";
import Navigation from "../common/Navigation";
import OrientationSwitch from "../common/OrientationSwitch";
import ShowcaseArtwork from "./ShowcaseArtwork";
import Printing from "../../data/Printing";
import BuyOriginalButton from "./BuyOriginalButton";
import BuyPrintingsButton from "./BuyPrintingsButton";
import { fontSize } from "../../constant";
import Artwork from "../../data/Artwork";
import { useArtwork } from "../../redux/hooks";

export interface ShowcaseState {
    artwork: Artwork;
}

export default function Showcase(): JSX.Element {
    const artwork = useArtwork();

    return <div className="flex-column" style={{
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        gap: "2vh",
    }}>
        <Navigation
            returnURL="/"
            returnText="Return to Gallery"
        />
        {getContent(artwork)}
    </div>;
}

function getContent(artwork: Artwork | null): JSX.Element | null {
    if (artwork === null) {
        /* TODO fine for normal behavior where we are just waiting for inventory
         * fetch, but in failure state we should probably kick back to the
         * gallery at least. */
        return null
    }

    const title = <p style={{
        fontSize: fontSize.superheader,
    }}>
        {artwork.name}
    </p>;

    const image = <ShowcaseArtwork artwork={artwork} />;

    const description = <p style={{
        color: xcolor.off_white,
        fontSize: fontSize.subheader,
    }}>
        <i>
            "{artwork.description}"
        </i>
    </p>;

    const buyOriginalButton = <BuyOriginalButton
        artwork={artwork}
        original={artwork.printings.find(
            printing => printing.isOriginal,
        ) as Printing}
    />;

    const buyPrintingsButton = <BuyPrintingsButton
        artwork={artwork}
        options={artwork.printings.filter(
            printing => !printing.isOriginal,
        )}
    />;

    const components: Components = {
        title: title,
        image: image,
        description: description,
        original: buyOriginalButton,
        printings: buyPrintingsButton,
    };

    return <OrientationSwitch
        portrait={Portrait(components)}
        landscape={Landscape(components)}
    />;
}

interface Components {
    title: JSX.Element;
    image: JSX.Element;
    description: JSX.Element;
    original: JSX.Element;
    printings: JSX.Element;
}

function Portrait(components: Components): JSX.Element {
    return <div style={{
        display: "flex",
        justifyContent: "center",
    }}>
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            gap: "3vh",
            paddingBottom: "3vh",
        }}>
            {components.title}
            {components.image}
            <div style={{
                textAlign: "center",
            }}>
                {components.description}
            </div>
            {components.original}
            {components.printings}
        </div>
    </div>;
}

function Landscape(components: Components): JSX.Element {
    return <div style={{
        display: "flex",
        justifyContent: "center",
        boxSizing: "border-box",
        paddingTop: "12vh",
    }}>
        <div className="flex-row" style={{
            width: "85%",
            // don't bloat too large for no reason
            maxWidth: "1400px",
            gap: "8%",
        }}>
            <div className="flex-column" style={{
                width: "49%",
                gap: "4vh",
                textAlign: "left",
            }}>
                {components.image}
                {components.description}
            </div>
            <div className="flex-column" style={{
                width: "43%",
                textAlign: "center",
                gap: "4vh",
            }}>
                <div style={{
                    paddingTop: "4vh",
                    paddingBottom: "2vh",
                }}>
                    {components.title}
                </div>
                {components.original}
                {components.printings}
            </div>
        </div>
    </div>;
}