// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --teacup-black: #1E1E24;
  --teacup-gray: #383843;
  --teacup-light-gray: #4A4A59;
  --teacup-white: #F5F1E3;
  --teacup-off-white: #AFACAC;
  --teacup-blue: #245E6B;
  --teacup-light-blue: #2E798A;
  --teacup-pink: #95818D;
  --teacup-red: #EE6055;
  --teacup-dark-red: #EB3F33;
}

body,
html {
  height: 100%;
}

body {
  line-height: 1;
}

/* Cleanup annoying default properties. */
html,
body,
div,
img,
form,
label,
section,
h1,
h2,
h3,
h4,
p,
ul,
li {
  margin: 0;
  padding: 0;
  border-width: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  text-decoration: none;
}

button {
  font: inherit;
  color: inherit;
}

td,
th {
  padding: 0;
}

/* Remove gap under images. */
img {
  display: block;
}

/* Remove gap under text areas. */
textarea {
  vertical-align: top;
}

/* Remove increment/decrement arrows on numerical inputs. */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove increment/decrement arrows on numerical inputs; Firefox. */
input[type=number] {
  -moz-appearance: textfield;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,sBAAsB;EACtB,4BAA4B;EAC5B,uBAAuB;EACvB,2BAA2B;EAC3B,sBAAsB;EACtB,4BAA4B;EAC5B,sBAAsB;EACtB,qBAAqB;EACrB,0BAA0B;AAC5B;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA,yCAAyC;AACzC;;;;;;;;;;;;;;EAcE,SAAS;EACT,UAAU;EACV,eAAe;EACf,eAAe;EACf,oBAAoB;EACpB,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;;EAEE,UAAU;AACZ;;AAEA,6BAA6B;AAC7B;EACE,cAAc;AAChB;;AAEA,iCAAiC;AACjC;EACE,mBAAmB;AACrB;;AAEA,2DAA2D;AAC3D;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,oEAAoE;AACpE;EACE,0BAA0B;AAC5B","sourcesContent":[":root {\n  --teacup-black: #1E1E24;\n  --teacup-gray: #383843;\n  --teacup-light-gray: #4A4A59;\n  --teacup-white: #F5F1E3;\n  --teacup-off-white: #AFACAC;\n  --teacup-blue: #245E6B;\n  --teacup-light-blue: #2E798A;\n  --teacup-pink: #95818D;\n  --teacup-red: #EE6055;\n  --teacup-dark-red: #EB3F33;\n}\n\nbody,\nhtml {\n  height: 100%;\n}\n\nbody {\n  line-height: 1;\n}\n\n/* Cleanup annoying default properties. */\nhtml,\nbody,\ndiv,\nimg,\nform,\nlabel,\nsection,\nh1,\nh2,\nh3,\nh4,\np,\nul,\nli {\n  margin: 0;\n  padding: 0;\n  border-width: 0;\n  font-size: 100%;\n  font-family: inherit;\n  vertical-align: baseline;\n  text-decoration: none;\n}\n\nbutton {\n  font: inherit;\n  color: inherit;\n}\n\ntd,\nth {\n  padding: 0;\n}\n\n/* Remove gap under images. */\nimg {\n  display: block;\n}\n\n/* Remove gap under text areas. */\ntextarea {\n  vertical-align: top;\n}\n\n/* Remove increment/decrement arrows on numerical inputs. */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Remove increment/decrement arrows on numerical inputs; Firefox. */\ninput[type=number] {\n  -moz-appearance: textfield;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
