import { PropsWithChildren } from "react";
import styled from "styled-components";
import { MediaDynamicValue } from "./dynamic/types";
import { useDynamicStyling } from "./dynamic/hooks";
import { DynamicRule } from "./dynamic/DynamicRule";

type ContentAlign = (
    | 'top-left'
    | 'top'
    | 'top-right'
    | 'left'
    | 'center'
    | 'right'
    | 'bottom-left'
    | 'bottom'
    | 'bottom-right'
);

type Props = {
    align?: ContentAlign;
    fullHeight?: boolean;
    gap?: MediaDynamicValue<string>;
} & PropsWithChildren;

export function Flex(props: Props): JSX.Element {
    const rules = useDynamicStyling(
        DynamicRule.create(props.gap, value => `gap: ${value};`),
    );

    const align = props.align ?? 'top-left';

    return <DitzyFlex
        _direction={'row'}
        _primaryAlign={getXAlign(align)}
        _secondaryAlign={getYAlign(align)}
        _height={props.fullHeight ? "100%" : "auto"}
        _rules={rules}
    >
        {props.children}
    </DitzyFlex>;
}

export function FlexColumn(props: Props): JSX.Element {
    const rules = useDynamicStyling(
        DynamicRule.create(props.gap, value => `gap: ${value};`),
    );

    const align = props.align ?? 'top-left';

    return <DitzyFlex
        _direction={'column'}
        _primaryAlign={getYAlign(align)}
        _secondaryAlign={getXAlign(align)}
        _height={props.fullHeight ? "100%" : "auto"}
        _rules={rules}
    >
        {props.children}
    </DitzyFlex>;
}

const DitzyFlex = styled.div<{
    _direction: 'row' | 'column';
    _primaryAlign: 'flex-start' | 'center' | 'flex-end';
    _secondaryAlign: 'flex-start' | 'center' | 'flex-end';
    _height: '100%' | 'auto';
    _rules: string;
}>`
    width: 100%;
    height: ${props => props._height};
    display: flex;
    flex-direction: ${props => props._direction};
    justify-content: ${props => props._primaryAlign};
    align-items: ${props => props._secondaryAlign};
    ${props => props._rules} // no semicolon
`;

function getXAlign(align: ContentAlign): 'flex-start' | 'center' | 'flex-end' {
    switch (align) {
        case 'top-left':
        case 'left':
        case 'bottom-left':
            return 'flex-start';
        case 'top':
        case 'center':
        case 'bottom':
            return 'center';
        case 'top-right':
        case 'right':
        case 'bottom-right':
            return 'flex-end';
    }
};

function getYAlign(align: ContentAlign): 'flex-start' | 'center' | 'flex-end' {
    switch (align) {
        case 'top-left':
        case 'top':
        case 'top-right':
            return 'flex-start';
        case 'left':
        case 'center':
        case 'right':
            return 'center';
        case 'bottom-left':
        case 'bottom':
        case 'bottom-right':
            return 'flex-end';
    }
}