export const color = {
    BLACK: `--teacup-black`,
    GRAY: `--teacup-gray`,
    LIGHT_GRAY: `--teacup-light-gray`,
    WHITE: `--teacup-white`,
    OFF_WHITE: `--teacup-off-white`,
    BLUE: `--teacup-blue`,
    LIGHT_BLUE: `--teacup-light-blue`,
    PINK: `--teacup-pink`,
    RED: `--teacup-red`,
    DARK_RED: `--teacup-dark-red`,
};

export const fsize = {
    SMALL: `clamp(0px, 1.4vw, 16px)`,
    MEDIUM: `clamp(0px, 1.75vw, 20px)`,
    LARGE: `clamp(0px, 2.1vw, 24px)`,
    MLARGE: `clamp(0px, 2.45vw, 28px)`,
    XLARGE: `clamp(0px, 4.2vw, 48px)`,
};

export const size = {
    s1: `clamp(0px, 0.5vw, 3px)`,
    s2: `clamp(0px, 1vw, 6px)`,
    s3: `clamp(0px, 2vw, 12px)`,
    s4: `clamp(0px, 3.8vw, 22.8px)`,
    m1: `clamp(0px, 4.5vw, 27px)`,
    m2: `clamp(0px, 5vw, 30px)`,
    m3: `clamp(0px, 6vw, 36px)`,
    m4: `clamp(0px, 7vw, 42px)`,
    l2: `clamp(0px, 8vw, 48px)`,
    l3: `clamp(0px, 9vw, 56px)`,
};

export const xcolor = {
    black: `var(--teacup-black)`,
    gray: `var(--teacup-gray)`,
    light_gray: `var(--teacup-light-gray)`,
    white: `var(--teacup-white)`,
    off_white: `var(--teacup-off-white)`,
    blue: `var(--teacup-blue)`,
    light_blue: `var(--teacup-light-blue)`,
    pink: `var(--teacup-pink)`,
    red: `var(--teacup-red)`,
    dark_red: `var(--teacup-dark-red)`,
};

export const fontSize = {
    headline: "clamp(0px, 8vw, 40px)",
    superheader: "clamp(0px, 7vw, 35px)",
    header: "clamp(0px, 6vw, 30px)",
    subheader: "clamp(0px, 5vw, 25px)",
    body: "clamp(0px, 4vw, 20px)",
    smol: "clamp(0px, 3.5vw, 17.5px)",
};

export const token = {
    bull: <span>&bull;</span>, // •
    times: <span>&times;</span>, // ×
    leftArrow: <span>&#x2770;</span>, // ⟵
};

export const CF_ACCOUNT_HASH = 'DQ77eGcS5R2uJLUTfe5SHA';
export const BUSINESS_EMAIL = 'teriartvibe@gmail.com';