import { useState } from "react";
import { useCloudflare } from "../../../api/Cloudflare";

enum AuthState {
    IDLE,
    PROCESSING,
    ERROR,
}

export default function AuthDialog(): JSX.Element {
    const [state, setState] = useState<AuthState>(AuthState.IDLE);
    const [text, setText] = useState<string>('');
    const cloudflare = useCloudflare();

    const submitButton = <button
        className="auth-button"
        type="submit"
        onClick={() => {
            setState(AuthState.PROCESSING);
            cloudflare.genAuthenticate(text).then(response => {
                /* Successful response will teardown this component. Therefore,
                 * we only worry about handling case where user did not provide
                 * valid token. */
                if (!response.success) {
                    setText('');
                    setState(AuthState.ERROR);
                }
            });
        }}
    >
        Submit
    </button>;

    const authForm = <div className="auth-form">
        <label>
            Authentication Token:
        </label>
        <input
            type="text"
            onChange={e => setText(e.target.value)}
            style={{ outline: "none" }}
        />
        {
            state === AuthState.ERROR &&
            <span className="auth-status">Invalid Token</span>
        }
        {submitButton}
    </div>;

    return <div style={{ marginTop: '4vh' }}>
        {
            state === AuthState.PROCESSING
                ? <p>Authenticating...</p>
                : authForm
        }
    </div>;
}