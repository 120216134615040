interface Props {
    portrait: JSX.Element;
    landscape: JSX.Element;
}

export default function OrientationSwitch(props: Props): JSX.Element {
    return <>
        <div className="portrait-only">
            {props.portrait}
        </div>
        <div className="landscape-only">
            {props.landscape}
        </div>
    </>;
}