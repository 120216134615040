import styled from "styled-components";
import { MediaDynamicValue } from "./dynamic/types";
import { DynamicRule } from "./dynamic/DynamicRule";
import { useDynamicStyling } from "./dynamic/hooks";

type BaseProps = {
    text: string;
    bold?: true;
    italic?: true;
    underline?: true;
    size?: MediaDynamicValue<string>;
};

type TextJustification = (
    | { leftJustify: true }
    | { centerJustify: true }
    | { rightJustify: true }
    | {}
);

type Props = BaseProps & TextJustification;

export function Text(props: Props): JSX.Element {
    const rules = useDynamicStyling(
        DynamicRule.create(
            props.size,
            value => `font-size: ${value};`,
        ),
    );

    return <DitzyText
        _justify={getJustification(props)}
        _rules={rules}
    >
        {getDecoratedText(props)}
    </DitzyText>;
}

const DitzyText = styled.div<{
    _justify: 'flex-start' | 'center' | 'flex-end';
    _rules: string;
}>`
    width: 100%;
    display: flex;
    text-align: ${props => props._justify};
    justify-content: ${props => props._justify};
    ${props => props._rules} // no semicolon
`;

function getJustification(props: Props): 'flex-start' | 'center' | 'flex-end' {
    if ('leftJustify' in props) {
        return 'flex-start';
    }
    if ('centerJustify' in props) {
        return 'center';
    }
    if ('rightJustify' in props) {
        return 'flex-end';
    }
    return 'flex-start';
}

function getDecoratedText(props: Props): JSX.Element {
    let text: JSX.Element = <p>{props.text}</p>;
    if (props.bold) {
        text = <b>{text}</b>;
    }
    if (props.italic) {
        text = <i>{text}</i>;
    }
    if (props.underline) {
        text = <u>{text}</u>;
    }
    return text;
}