import { MarginRule } from "./margin";
import { ExplicitMediaDynamicConfig, MediaDynamicConfig, MediaDynamicValue } from "./types";

const BREAK_TINY_SMALL = 310;
const BREAK_SMALL_MEDIUM = 480;
const BREAK_MEDIUM_LARGE = 960;

export class DynamicRule<T> {
    private config: (
        | { type: 'unspecified' }
        | { type: 'static', value: T }
        | { type: 'dynamic', value: ExplicitMediaDynamicConfig<T> }
    );
    private ruleFormatter: ((value: T) => string) | undefined;

    private constructor(
        value: MediaDynamicValue<T> | undefined,
        ruleFormatter: ((value: T) => string) | undefined,
    ) {
        if (value === undefined) {
            this.config = { type: 'unspecified' };
        } else if (
            typeof value === 'object' &&
            value !== null &&
            'small' in value &&
            'large' in value
        ) {
            this.config = { type: 'dynamic', value: DynamicRule.makeExplicit(value) };
        } else {
            // must be a static value
            this.config = { type: 'static', value };
        }

        this.ruleFormatter = ruleFormatter;
    }

    /**
     * Creates a new dynamic styling rule. Rules are intended as abstract
     * representations of responsive styling behavior. Their resolution to
     * useful CSS is deferred and memoized to improve rendering performance.
     * 
     * @param value Static or dynamic value to format CSS from. If undefined,
     *  this rule will render to an empty string unless subsumed by a more
     *  explicit rule.
     * @param ruleFormatter String formatter for creating CSS rule. Formatter
     *  may optionally be undefined ititially, but such rules will throw if not
     *  assigned a formatter before resolution.
     */
    public static create<T>(
        value: MediaDynamicValue<T> | undefined,
        ruleFormatter?: ((value: T) => string),
    ): DynamicRule<T> {
        return new DynamicRule(value, ruleFormatter);
    }

    /**
     * Convenience function in place of calling create multiple times.
     */
    public static createMany<T>(
        values: (MediaDynamicValue<T> | undefined)[],
        ruleFormatter?: ((value: T) => string),
    ): [...DynamicRule<T>[]] {
        return values.map(value => DynamicRule.create(value, ruleFormatter));
    }

    /**
     * Combine each provided rule into a single rule by the following pattern:
     * 
     *  1. If all rules are unspecified, the resulting rule will be unspecified.
     * 
     *  2. If all rules are static or unspecified, the resulting rule will be a
     *     static concatenation of each provided rule.
     * 
     *  3. If any rule is dynamic, the resulting rule will be a dynamic
     *     concatenation of each provided rule, with unspecified rules omitted
     *     and static rules expanded to dynamic artificially.
     * 
     * In any case, each provided rule is resolved before concatenation.
     * Concatention here being simple joining via newline characters.
     */
    public static combine(rules: DynamicRule<any>[]): DynamicRule<string> {
        const specifiedRules = rules.filter(rule => rule.config.type !== 'unspecified');
        if (specifiedRules.length < 1) {
            // no actual rules to speak of; return empty rule
            return DynamicRule.create<string>(undefined);
        }

        if (specifiedRules.every(rule => rule.config.type === 'static')) {
            // all rules are static; preserve this simpler case and simply join
            return DynamicRule.create(
                specifiedRules.map(rule => rule.resolve()).join('\n'),
                value => value,
            );
        }

        /* At least some rules are dynamic which means combined rule must also
         * be dynamic. In any case, resolve each rule to its resulting, dynamic
         * CSS rule strings for combinational use. */
        const dynamicRules = specifiedRules.map(
            rule => rule.makeDynamic().resolveDynamic(),
        );

        // create a single, dynamic string rule by joining each rule
        return DynamicRule.create(
            {
                tiny: dynamicRules.map(rule => rule.tiny).join('\n\t'),
                small: dynamicRules.map(rule => rule.small).join('\n\t'),
                medium: dynamicRules.map(rule => rule.medium).join('\n\t'),
                large: dynamicRules.map(rule => rule.large).join('\n\t'),
            },
            value => value,
        );
    }

    /**
     * If this rule is unspecified, defer to the other, provided rule.
     */
    public default(other: DynamicRule<T>): DynamicRule<T> {
        return this.config.type === 'unspecified' ? other : this;
    }

    /**
     * Set CSS rule formatter. Convenient for deferring formatter assignment
     * from rule creation time.
     */
    public formatter(ruleFormatter: (value: T) => string): DynamicRule<T> {
        this.ruleFormatter = ruleFormatter;
        return this;
    }

    /**
     * Render this rule to a useful CSS string. This should only be performed
     * once per component render cycle.
     */
    public resolve(): string {
        switch (this.config.type) {
            case 'unspecified':
                return '';
            case 'static':
                return this.format(this.config.value);
            case 'dynamic':
                return DynamicRule.applyMediaQueries(this.resolveDynamic());
        }
    }

    ////////////////////////////////////////////////////////////////////////////
    // Private Helper Functions

    private format(value: T): string {
        if (!this.ruleFormatter) {
            throw new Error('missing formatter');
        }
        let result = this.ruleFormatter(value);
        if (!result.endsWith(';')) {
            console.warn('rule missing semicolon:', result);
            result += ';';
        }
        return result;
    }

    private resolveDynamic(): ExplicitMediaDynamicConfig<string> {
        if (this.config.type !== 'dynamic') {
            throw new Error('rule cannot dynamically resolve');
        }
        return {
            tiny: this.format(this.config.value.tiny),
            small: this.format(this.config.value.small),
            medium: this.format(this.config.value.medium),
            large: this.format(this.config.value.large),
        };
    }

    private makeDynamic(): DynamicRule<T> {
        switch (this.config.type) {
            case 'unspecified':
                throw new Error('cannot make unspecified rule dynamic');
            case 'static':
                const value = this.config.value;
                return DynamicRule.create(
                    { tiny: value, small: value, medium: value, large: value },
                    this.ruleFormatter,
                );
            case 'dynamic':
                return this;
        }
    }

    private static applyMediaQueries(
        rules: ExplicitMediaDynamicConfig<string>,
    ): string {
        return [
            `@media (max-width: ${0}px) {\n\t${rules.tiny}\n}`,
            `@media (min-width: ${BREAK_TINY_SMALL}px) {\n\t${rules.small}\n}`,
            `@media (min-width: ${BREAK_SMALL_MEDIUM}px) {\n\t${rules.medium}\n}`,
            `@media (min-width: ${BREAK_MEDIUM_LARGE}px) {\n\t${rules.large}\n}`,
        ].join('\n');
    }

    /**
     * Infer optional, dynamic "tiny" and "medium" media breaking sizes if not
     * provided.
     */
    private static makeExplicit<T>(
        value: MediaDynamicConfig<T>,
    ): ExplicitMediaDynamicConfig<T> {
        return {
            tiny: value.tiny ?? value.small,
            small: value.small,
            medium: value.medium ?? value.large,
            large: value.large,
        };
    }

    ////////////////////////////////////////////////////////////////////////////
    // Sophisticated Rule Definitions

    public static Margin = MarginRule;
}