import React from "react";
import Artwork from "../../../../data/Artwork";
import ProtoArtwork, { ProtoPrinting } from "../../../../data/ProtoArtwork";
import Printing from "../../../../data/Printing";
import { util } from "../../../../util";
import { useCloudflare } from "../../../../api/Cloudflare";

export interface Editor {
    submit: (cb: {
        onError: (errors: string[]) => void,
        onSuccess: () => void,
    }) => void;
    image: {
        value: string | null;
        update: (file: File) => void;
    };
    name: {
        value: string;
        update: (updatedValue: string) => void;
    };
    description: {
        value: string;
        update: (updatedValue: string) => void;
    };
    medium: {
        value: string;
        update: (updatedValue: string) => void;
    };
    categories: {
        value: string[];
        add: (category: string) => void;
        remove: (category: string) => void;
    };
    priority: {
        value: string;
        update: (updatedValue: string) => void;
    };
    original: {
        value: ProtoPrinting | null;
        update: (updatedOriginal: ProtoPrinting) => void;
    };
    printings: {
        value: ProtoPrinting[];
        add: (printing: ProtoPrinting) => void;
        remove: (byWidth: number) => void;
    };
}

type EditorState = {
    /**
     * Null if user has not yet selected an artwork image or if this is an edit
     * and the user has not decided to change the image in use.
     */
    imageUploadFile: File | null;
    /**
     * Stream data read from uploaded image File. Intended to be used directly
     * as img element src attribute.
     */
    imageUploadSource: string | null;
    name: string;
    description: string;
    medium: string;
    categories: string[];
    priority: string;
    original: Printing | ProtoPrinting | null;
    printings: (Printing | ProtoPrinting)[];
}

export function useEditor(existing: Artwork | null): Editor {
    const [state, setState] = React.useState<EditorState>({
        imageUploadFile: null,
        imageUploadSource: existing ? util.getImageURL(existing) : null,
        name: existing?.name ?? "",
        description: existing?.description ?? "",
        medium: existing?.medium ?? "",
        categories: existing?.categories ?? [],
        priority: `${existing?.priority ?? "0"}`,
        original: existing?.printings?.find(p => p.isOriginal) ?? null,
        printings: existing?.printings?.filter(p => !p.isOriginal) ?? [],
    });

    const cloudflare = useCloudflare();

    return {
        submit: cb => genSubmit(
            existing,
            state,
            cloudflare,
            cb.onError,
            cb.onSuccess,
        ),
        image: {
            value: state.imageUploadSource,
            update: file => {
                setState({
                    ...state,
                    imageUploadFile: file,
                    // mark source as null to indicate File read is in progress
                    imageUploadSource: null,
                    // image change necessitates new product creation
                    original: state.original ? asProtoPrinting(state.original) : null,
                    printings: state.printings.map(p => asProtoPrinting(p)),
                });
                const stream = new FileReader();
                /* Callback on image read completion. Needs to use previous
                 * state lambda to avoid overwriting set image File. */
                stream.onloadend = () => setState(prev => ({
                    ...prev,
                    imageUploadSource: stream.result as string,
                }));
                // initiate image read
                stream.readAsDataURL(file);
            },
        },
        name: {
            value: state.name,
            update: value => setState({ ...state, name: value }),
        },
        description: {
            value: state.description,
            update: value => setState({ ...state, description: value })
        },
        medium: {
            value: state.medium,
            update: value => setState({ ...state, medium: value })
        },
        categories: {
            value: state.categories,
            add: category => setState({
                ...state,
                categories: state.categories.concat(category.trim().toLowerCase()),
            }),
            remove: category => setState({
                ...state,
                categories: state.categories.filter(cat => cat !== category),
            }),
        },
        priority: {
            value: state.priority,
            update: value => setState({ ...state, priority: value }),
        },
        original: {
            value: state.original,
            update: (value: ProtoPrinting) => {
                const aspectRatio = value.width / value.height;
                setState({
                    ...state,
                    original: { ...value },
                    printings: correctAspectRatios(state.printings, aspectRatio),
                });
            },
        },
        printings: {
            value: state.printings,
            add: (printing: ProtoPrinting) => {
                if (state.printings.some(p => p.width === printing.width)) {
                    throw new Error();
                }
                setState({
                    ...state,
                    printings: state.printings.concat(printing),
                });
            },
            remove: byWidth => {
                const printing = state.printings.find(p => p.width === byWidth);
                if (!printing) {
                    throw new Error();
                }
                setState({
                    ...state,
                    printings: state.printings.filter(p => p.width !== byWidth),
                });
            },
        },
    };
}

function asProtoPrinting(printing: Printing | ProtoPrinting): ProtoPrinting {
    // same as Printing but shed Stripe ID
    return {
        width: printing.width,
        height: printing.height,
        price: printing.price,
        isOriginal: printing.isOriginal,
        isAvailable: printing.isAvailable,
    };
}

function correctAspectRatios(
    printings: (Printing | ProtoPrinting)[],
    aspectRatio: number,
): ProtoPrinting[] {
    return printings.map(printing => {
        const expectedHeight = Math.round((printing.width / aspectRatio) * 100) / 100;
        return printing.height === expectedHeight
            ? printing
            // make sure to convert to ProtoPrinting in case aspect ratio change
            : { ...asProtoPrinting(printing), height: expectedHeight };
    });
}

async function genSubmit(
    existing: Artwork | null,
    form: EditorState,
    cloudflare: ReturnType<typeof useCloudflare>,
    onErrors: (errors: string[]) => void,
    onSuccess: () => void,
): Promise<void> {
    const name: string = form.name.trim();
    const priority: number = parseFloat(form.priority);

    if (name.length < 1) {
        onErrors(['cannot begin image upload without a name']);
        return;
    }
    if (isNaN(priority)) {
        onErrors(['listing priority must be a number']);
        return;
    }
    if (existing === null && form.imageUploadFile === null) {
        onErrors(['cannot upload without an image']);
        return;
    }

    let submissionCFID: string;
    let imageUploadURL: string | null = null;

    if (form.imageUploadFile !== null) {
        /* User wants to upload a new image, maybe replacing an existing one.
         * We won't actually perform this upload until we have completed form
         * validation with our host server. In the meanwhile, ask Cloudflare to
         * generate a draft cfID which will become permanent following our
         * eventual upload.
         * 
         * Note that if form validation fails, the dangling draft we create here
         * will be harmless and expire after 30 minutes. */
        try {
            const draftUploadResponse = await cloudflare.genImageUploadURL();
            if (!draftUploadResponse.success) {
                throw new Error('Cloudflare refused image upload');
            }
            submissionCFID = draftUploadResponse.data.cfID;
            imageUploadURL = draftUploadResponse.data.uploadURL;
        } catch (error) {
            onErrors([error instanceof Error ? error.message : 'image upload failed']);
            return;
        }
    } else {
        /* Use existing image already within Cloudflare. Assertion here is safe
         * due to previous check. */
        submissionCFID = (existing as Artwork).cfID;
    }

    // determine which existing products are not being replaced or deleted
    const survivingStripeIDs: string[] = form.printings
        .concat(form.original ?? [])
        .filter(survivingPrinting => 'stripeID' in survivingPrinting)
        .map(survivingPrinting => (survivingPrinting as Printing).stripeID);

    // mark any existing, removed Printings for deletion
    const printingsToDelete: string[] = (existing?.printings ?? [])
        .filter(existingPrinting => !survivingStripeIDs.includes(existingPrinting.stripeID))
        .map(existingPrinting => existingPrinting.stripeID);

    // mark remaining Printings which did not previously exist for creation
    const printingsToCreate: ProtoPrinting[] = form.printings
        .concat(form.original ?? [])
        .filter(remainingPrinting => !('stripeID' in remainingPrinting));

    const prototype: ProtoArtwork = {
        cfID: submissionCFID,
        name: name,
        description: form.description.trim(),
        medium: form.medium.trim(),
        categories: form.categories,
        priority: priority,
        printings: {
            delete: printingsToDelete,
            create: printingsToCreate,
        },
    };

    // make request to host server, performing validation
    const submissionResponse = await cloudflare.genCreateArtwork(prototype);
    if (!submissionResponse.success) {
        // return validation/processing errors for display
        onErrors(submissionResponse.errors);
        return;
    }

    if (form.imageUploadFile !== null && imageUploadURL !== null) {
        /* Validation was successful--we're in the hot zone now. Perform image
         * upload to draft location provided by Cloudflare earlier.
         * 
         * For consistency, we rename a copy of the File provided by the user to
         * a tidier format. */
        const renamedFile = new File(
            [form.imageUploadFile],
            // consistent name is snake-cased with no whitespace
            name.toLowerCase().replace(/[^a-z0-9]/g, '_'),
            { type: form.imageUploadFile.type },
        );

        // perform upload to draft location provided by Cloudflare
        const uploadResponse = await cloudflare.genUploadImage(
            imageUploadURL,
            renamedFile,
        );

        if (!uploadResponse.success) {
            /* This is pretty bad. We really don't want Artwork data inside our
             * database without an associated image. Try to delete the Artwork
             * data we just created to salvage the situation. */
            const deletionResponse = await cloudflare.genDeleteArtwork(submissionCFID);
            if (!deletionResponse.success) {
                /* This is catastrophic. Log everything we can as human
                 * intervention is the only way to clean up the situation. */
                const message: string = (
                    `[CRITICAL ERROR]\n\n` +
                    `Created Artwork object but failed to complete image upload.\n` +
                    `CFID: ${submissionCFID}\n\n` +
                    `If you are seeing this, please contact a developer immediately.`
                );
                console.error(message);
                throw new Error(message);
            }

            // we landed on our feet, but upload still failed
            onErrors(['provided image could not be uploaded']);
            return;
        }

        // request deletion of previous image if this was an edit
        if (existing !== null) {
            const deletionResponse = await cloudflare.genDeleteArtwork(existing.cfID);
            if (!deletionResponse.success) {
                const message: string = (
                    `[CRITICAL ERROR]\n\n` +
                    `Failed to delete previous, updated Artwork.\n` +
                    `CFID: ${existing.cfID}\n\n` +
                    `If you are seeing this, please contact a developer immediately.`
                );
                console.error(message);
                throw new Error(message)
            }
        }
    }

    // if we made it this far, everything went off without a hitch
    onSuccess();
}