import { PropsWithChildren } from "react";
import { useDynamicStyling } from "./dynamic/hooks";
import styled from "styled-components";
import { MediaDynamicValue } from "./dynamic/types";
import { DynamicRule } from "./dynamic/DynamicRule";

type Props = (
    {
        width?: MediaDynamicValue<string>;
        maxWidth?: number;
        paddingY?: MediaDynamicValue<string>;
        paddingX?: MediaDynamicValue<string>;
    }
    & PropsWithChildren
)

export function Box(props: Props): JSX.Element {
    const rules = useDynamicStyling(
        DynamicRule.create(
            props.width ?? '100%',
            value => `width: ${value};`,
        ),
        DynamicRule.create(
            props.maxWidth,
            value => `max-width: ${value}px;`,
        ),
        DynamicRule.create(
            props.paddingX,
            value => `padding-left: ${value};\npadding-right: ${value};`,
        ),
        DynamicRule.create(
            props.paddingY,
            value => `padding-top: ${value};\npadding-bottom: ${value};`,
        ),
    );

    return <DitzyBox _rules={rules}>
        {props.children}
    </DitzyBox>;
}

const DitzyBox = styled.div<{ _rules: string }>`
    display: flex;
    ${props => props._rules} // no semicolon
`;