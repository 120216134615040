import Artwork from "../../../data/Artwork";
import { PopoverControl } from "../popover/Popover";

interface Props {
    artwork: Artwork;
    control: PopoverControl;
}

export default function ActionsCell(props: Props): JSX.Element {
    // every Artwork has an original
    const original = props.artwork.printings.find(p => p.isOriginal)!;

    const MarkOriginalSold = () => <button
        className="inv-action-button"
        onClick={() => props.control.promptConfirmMarkSold(props.artwork)}
    >
        Mark Original Sold
    </button>;

    const OriginalAlreadySold = () => <button
        className="inv-action-button-inactive"
        onClick={() => null}
    >
        Original Sold
    </button>;

    const DeleteArtwork = () => <button
        className="inv-action-button-delete"
        onClick={() => props.control.promptConfirmDelete(props.artwork)}
    >
        Delete
    </button>;

    const BeginEditing = () => <button
        className="inv-action-button"
        onClick={() => props.control.beginEditing(props.artwork)}
    >
        Edit
    </button>;

    return <div className="inv-actions-cell">
        {original.isAvailable ? <MarkOriginalSold /> : <OriginalAlreadySold />}
        <DeleteArtwork />
        <BeginEditing />
    </div>;
}