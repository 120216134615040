import { PropsWithChildren } from "react";
import styled from "styled-components";
import { MediaDynamicValue } from "./dynamic/types";
import { useDynamicStyling } from "./dynamic/hooks";
import { DynamicRule } from "./dynamic/DynamicRule";

type Props = {
    columns: MediaDynamicValue<number>;
    gap?: MediaDynamicValue<string>;
    rowGap?: MediaDynamicValue<string>;
    colGap?: MediaDynamicValue<string>;
} & PropsWithChildren;

export function Grid(props: Props): JSX.Element {
    const gap = DynamicRule.create(props.gap, value => `gap: ${value};`);
    const rules = useDynamicStyling(
        DynamicRule.create(
            props.columns,
            value => `grid-template-columns: repeat(${value}, 1fr);`,
        ),
        DynamicRule.create(
            props.rowGap,
            value => `row-gap: ${value};`,
        ).default(gap),
        DynamicRule.create(
            props.colGap,
            value => `column-gap: ${value};`,
        ).default(gap),
    );

    return <DitzyGrid _rules={rules}>
        {props.children}
    </DitzyGrid>;
}

const DitzyGrid = styled.div<{ _rules: string }>`
    display: grid;
    width: 100%;
    ${props => props._rules} // no semicolon
`;