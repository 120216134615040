import { useState } from "react";
import { useCloudflare } from "../../../api/Cloudflare";
import Artwork from "../../../data/Artwork";
import { PopoverControl } from "./Popover";

interface Props {
    artwork: Artwork;
    control: PopoverControl;
}

export default function ConfirmDeletePopover(props: Props): JSX.Element {
    const cloudflare = useCloudflare();
    const [waiting, setWaiting] = useState<boolean>(false);

    return <div className="popover-confirm">
        <p>{
            waiting
                ? `Working on it...`
                : `Really delete "${props.artwork.name}"?`
        }</p>
        {!waiting && (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '2rem',
                width: '100%',
            }}>
                <button
                    className="popover-no-button"
                    onClick={() => props.control.close()}
                >
                    No, Cancel
                </button>
                <button
                    className="popover-yes-button"
                    onClick={() => {
                        setWaiting(true);
                        cloudflare.genDeleteArtwork(props.artwork.cfID).then(
                            response => {
                                if (response.success) {
                                    props.control.close();
                                } else {
                                    throw new Error('Failed to delete artwork.');
                                }
                            },
                        );
                    }}
                >
                    Yes, delete.
                </button>
            </div>
        )}
    </div>;
}