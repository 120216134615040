import ReactDOM from 'react-dom/client';
import styled from 'styled-components';
import Teacup from './Teacup';
import './index.css';
import { xcolor, fontSize } from './constant';

const redirect = () => {
	if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
		// IOS
		window.location.href = 'googlechrome://terischottlerart.com';
	} else {
		// Android
		window.location.href = 'intent:https://terischottlerart.com#Intent;end';
	}
}

if (window.navigator.userAgent.includes("Instagram")) {
	// Instagram will prompt user to accept
	redirect();
}

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const Root = styled.div`
    background-color: var(--teacup-black);
    font-family: 'Poppins', sans-serif;
    color: var(--teacup-white);
`;

const InstagramRedirect = <div style={{
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "100vh",
}}>
	<button
		style={{
			color: xcolor.white,
			fontSize: fontSize.subheader,
			border: "none",
			backgroundColor: "inherit",
		}}
		onClick={() => redirect()}
	>
		Open in Browser
	</button>
</div>;

root.render(<Root>
	{
		window.navigator.userAgent.includes("Instagram")
			? InstagramRedirect
			: <Teacup />
	}
</Root>);