import { Box } from "../../ditzy/Box";
import { Flex, FlexColumn } from "../../ditzy/Flex";
import { Text } from "../../ditzy/Text";

export default function GalleryFooter(): JSX.Element {
    return <Flex align='center'>
        <Box
            width="80%"
            paddingY='4vw'
        >
            <FlexColumn
                align='center'
                gap='2vw'
            >
                <Text
                    text="© 2024 Teri Schottler"
                    centerJustify
                    size={{
                        small: '1.0rem',
                        large: '1.4rem',
                    }}
                />
                <Text
                    text="Questions? Reach me at teriartvibe@gmail.com"
                    centerJustify
                    size={{
                        small: '0.8rem',
                        large: '1.0rem',
                    }}
                />
            </FlexColumn>
        </Box>
    </Flex>;
}