import { useParams } from "react-router-dom";
import Artwork from "../data/Artwork";
import { useSelector } from "react-redux";
import { State } from "./store";
import Printing from "../data/Printing";

/**
 * Convenience wrapper for cleaner front-end code.
 */
type CartItem = {
    stripeID: string;
    artwork: Artwork;
    printing: Printing;
    quantity: number;
}

/**
 * Use all current Artwork inventory.
 */
export function useInventory(): Artwork[] {
    return useSelector((state: State) => state.inventory.sort(
        // return inventory by descending priority as default
        (a, b) => b.priority - a.priority),
    );
}

/**
 * Use Redux cart state conveniently wrapped for ease of use. Null if Artwork
 * data backing the cart could not be found as is the case on first load before
 * updated inventory is fetched.
 */
export function useCart(): CartItem[] {
    const cart = useSelector((state: State) => state.cart);
    const inventory = useInventory();

    /* Because we initialize cart from local storage before React component
     * mount, we may not have yet fetched remote inventory. In this case, return
     * empty cart for the moment. */
    if (inventory.length < 1) return [];

    // construct map from Stripe ID to Artwork data for efficient lookup
    const inventoryMap = new Map<string, { artwork: Artwork, printing: Printing }>();
    inventory.forEach(artwork =>
        artwork.printings.forEach(printing =>
            inventoryMap.set(printing.stripeID, { artwork, printing }),
        ),
    );

    return cart.map(cartItem => ({
        stripeID: cartItem.stripeID,
        artwork: inventoryMap.get(cartItem.stripeID)!.artwork,
        printing: inventoryMap.get(cartItem.stripeID)!.printing,
        quantity: cartItem.quantity,
    }));
}

/**
 * Use single artwork from Redux state as specified by 'id' param. Null if no
 * artwork is found corresponding to the provided 'id' as is mostly the case on
 * first load before updated inventory is fetched.
 */
export function useArtwork(): Artwork | null {
    /* Recall that 'id' here is 'cfID' field on Artwork and uniquely identifies
     * that artwork within the inventory as well as directly linking to its
     * corresponding images within Cloudflare. */
    const { id } = useParams<{ id: string }>();
    const inventory = useInventory();

    return inventory.find(artwork => artwork.cfID === id) ?? null;
}

/**
 * Access authentication token from Redux state. Token stored in Redux state is
 * itself an active version of any value further stored in local, browser
 * storage.
 */
export function useAuth(): string | null {
    return useSelector((state: State) => state.authToken ?? null);
}