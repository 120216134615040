import React from "react";
import { Editor } from "./editor";

interface Props {
    editor: Editor;
}

export default function ImageArea({ editor }: Props): JSX.Element {
    const selectFileRef = React.useRef(null);

    const getImage = () => {
        if (editor.image.value) {
            return <img
                src={editor.image.value}
                style={{
                    width: "20vw",
                }}
            />;
        } else {
            return <div style={{
                width: "20vw",
                height: "0px",
            }} />;
        }
    }

    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        alignItems: "center",
    }}>
        {getImage()}
        <input
            type="file"
            accept=".png, .jpeg, .jpg"
            ref={selectFileRef}
            style={{ display: "none" }}
            onChange={e => editor.image.update((e.target.files as FileList)[0])}
        />
        <button
            className="edit-select-img-button"
            onClick={() => (selectFileRef.current as any).click()}
        >
            {editor.image.value ? "Select a Different Image" : "Select Image"}
        </button>
    </div>;
}