import { useInventory } from "../../../redux/hooks";
import { headers } from "../const";
import { PopoverControl } from "../popover/Popover";
import InventoryTableRow from "./InventoryTableRow";

interface Props {
    control: PopoverControl;
}

export default function InventoryTable(props: Props): JSX.Element {
    const artworks = useInventory();

    const createNewButton = <div style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginBottom: "2vh",
    }}>
        <button
            className="artwork-edit-submit-button"
            onClick={() => props.control.beginEditing(null)}
        >
            Create New
        </button>
    </div>;

    return <div className="inv-table-container">
        {createNewButton}
        <div className="inv-table">
            <div className="inv-tr" key='header'>
                {headers.map(([key, width]) => <div
                    key={key}
                    className="inv-cell"
                    style={{ width: `${width}%` }}
                >
                    {key}
                </div>)}
            </div>
            {artworks.map(artwork => <InventoryTableRow
                key={artwork.cfID}
                artwork={artwork}
                control={props.control}
            />)}
        </div>
    </div>;
}