import { useState } from "react";
import { useCloudflare } from "../../../api/Cloudflare";
import Artwork from "../../../data/Artwork";
import { PopoverControl } from "./Popover";

interface Props {
    artwork: Artwork;
    control: PopoverControl;
}

export default function ConfirmSoldPopover(props: Props): JSX.Element {
    const cloudflare = useCloudflare();
    const [waiting, setWaiting] = useState<boolean>(false);

    // every Artwork has an original
    const original = props.artwork.printings.find(p => p.isOriginal)!;

    return <div className="popover-confirm">
        <p>{
            waiting
                ? `Working on it...`
                : `Mark original for "${props.artwork.name}" sold?`
        }</p>
        {!waiting && (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '2rem',
                width: '100%',
            }}>
                <button
                    className="popover-no-button"
                    onClick={() => props.control.close()}
                >
                    No, Cancel
                </button>
                <button
                    className="popover-yes-button"
                    onClick={() => {
                        setWaiting(true);
                        cloudflare.genMarkOriginalSold(original.stripeID).then(
                            response => {
                                if (response.success) {
                                    props.control.close();
                                } else {
                                    throw new Error('Failed to mark original sold.');
                                }
                            },
                        );
                    }}
                >
                    Yes, it's gone!
                </button>
            </div>
        )}
    </div>;
}