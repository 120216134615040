import { DynamicRule } from "./DynamicRule";
import { MediaDynamicValue } from "./types";

export type MarginType = (
    | 'none'
    | 'm1' // 4vw
    | 'm2' // 8vw
    | 'm3' // 12vw
    | 'm4' // 16vw
    | 'm5' // 20vw
)

function getMargin(margin: MarginType): string {
    switch (margin) {
        case 'none':
            return '0';
        case 'm1':
            return '4vw';
        case 'm2':
            return '8vw';
        case 'm3':
            return '12vw';
        case 'm4':
            return '16vw';
        case 'm5':
            return '20vw';
    }
}

export const MarginRule = (
    props: {
        margin?: MediaDynamicValue<MarginType>;
        marginY?: MediaDynamicValue<MarginType>;
        marginX?: MediaDynamicValue<MarginType>;
        marginTop?: MediaDynamicValue<MarginType>;
        marginRight?: MediaDynamicValue<MarginType>;
        marginBottom?: MediaDynamicValue<MarginType>;
        marginLeft?: MediaDynamicValue<MarginType>;
    },
): DynamicRule<string> => {
    const [
        margin,
        marginY,
        marginX,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
    ] = DynamicRule.createMany([
        props.margin,
        props.marginY,
        props.marginX,
        props.marginTop,
        props.marginRight,
        props.marginBottom,
        props.marginLeft,
    ]);

    return DynamicRule.combine([
        marginTop.default(marginY).default(margin).formatter(value => `margin-top: ${getMargin(value)};`),
        marginRight.default(marginX).default(margin).formatter(value => `margin-right: ${getMargin(value)};`),
        marginBottom.default(marginY).default(margin).formatter(value => `margin-bottom: ${getMargin(value)};`),
        marginLeft.default(marginX).default(margin).formatter(value => `margin-left: ${getMargin(value)};`),
    ]);
}