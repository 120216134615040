import styled from "styled-components";
import { xcolor, fontSize, token } from "../../constant";
import Artwork from "../../data/Artwork";
import Printing from "../../data/Printing";
import { util } from "../../util";

interface Props {
    artwork: Artwork;
}

/**
 * Encapsulates artwork image and subtitle including medium, publish year, and
 * original dimensions.
 */
export default function ShowcaseArtwork({ artwork }: Props): JSX.Element {
    // publish date is formatted MM-DD-YYYY
    const publishYear = artwork.publishDate.split('-')[2];
    const original = artwork.printings.find(printing => printing.isOriginal) as Printing;

    const dimensions = <span>
        {original.width.toFixed(0)}{token.times}{original.height.toFixed(0)}"
    </span>;

    const subtitle = <Caption><i>
        {publishYear} {token.bull} {artwork.medium} {token.bull} {dimensions}
    </i></Caption>;

    return <div className="flex-column" style={{
        width: "100%",
        gap: "2vh",
        textAlign: "center",
    }}>
        <Image src={util.getImageURL(artwork)} />
        {subtitle}
    </div>;
}

const Image = styled.img`
    box-sizing: border-box;

    width: 100%;

    border-width: clamp(0px, 2vw, 16px);
    border-style: solid;
    border-color: ${xcolor.white};
`;

const Caption = styled.p`
    font-size: ${fontSize.body};

    color: ${xcolor.off_white};
`;