import { State } from "./store";

const CART_KEY = 'teacup_cart';
const CART_EXP_KEY = 'teacup_expiration';
const AUTH_KEY = 'teacup_auth';

const MS_IN_DAY = 1000 * 60 * 60 * 24;
const EXP_TIME_MS = MS_IN_DAY * 2;

/* User may have disabled local storage. If so, we will error and not try again.
 * Fool me once. */
let use_cache = true;

/**
 * Initialize Redux state on app first load, using data cached in local storage
 * if available. Cached data includes user cart from previous sessions (within
 * an expiration limit) as well as authentication token for making privileged
 * requests.
 */
export function initFromCache(): State {
    let initState: State = {
        cart: [],
        inventory: [],
        authToken: undefined,
    };

    /* Wrap local storage access because user settings may cause this access to
     * fail. Additionally, during debugging these values are volatile. */
    try {
        const cachedCart = window.localStorage.getItem(CART_KEY);
        if (cachedCart) {
            const cachedExpTime = window.localStorage.getItem(CART_EXP_KEY);
            // even if cached cart exists, only use it if it is not expired
            if (cachedExpTime && JSON.parse(cachedExpTime) > Date.now()) {
                // cart exists and is yet to expire
                initState.cart = JSON.parse(cachedCart);
            }
        }

        // check for cached auth key
        const cachedAuth = window.localStorage.getItem(AUTH_KEY);
        if (cachedAuth) initState.authToken = cachedAuth;
    } catch (_) {
        // nothing to do but log for debugging
        console.log('error reading cart data from local storage');
    }

    return initState;
}

/**
 * Cache inter-session data within browser local storage. This includes the
 * user's current cart content as well as authentication token if this is a
 * privileges session.
 */
export function writeToCache(state: State): void {
    if (!use_cache) return;

    try {
        /* Always write cart contents to local storage. This may result in
         * some unnecessary writes, but this method avoids having to perform
         * more action-specific writes or reading existing state each time for
         * comparison. */
        window.localStorage.setItem(CART_KEY, JSON.stringify(state.cart));
        window.localStorage.setItem(CART_EXP_KEY, JSON.stringify(Date.now() + EXP_TIME_MS));

        // write auth token or clear if undefined
        if (state.authToken) {
            window.localStorage.setItem(AUTH_KEY, state.authToken);
        } else {
            window.localStorage.removeItem(AUTH_KEY);
        }
    } catch (_) {
        console.log('unable to write this session to local storage');
        use_cache = false;
    }
}